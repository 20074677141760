import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { clsx } from 'clsx';
import { ChangeEvent } from 'react';

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const SearchInput = (props: SearchInputProps) => {
  const { disabled, onChange, value } = props;

  return (
    <div
      className={clsx(
        'flex flex-row w-[342px] max-h-spacing11 items-center px-spacing5 py-spacing5 rounded-spacing15 border focus:border-spacing2 focus:border-border-dark bg-surface-primary border-border-grayInputDefault hover:border-border-brandSecondary',
        {
          'border-border-grayControlsDefaultDisabled bg-surface-disabled':
            disabled,
          'border-border-brandSecondary': value,
        },
      )}
    >
      <SearchIcon
        className={clsx('mr-spacing4 fill-icon-primary', {
          'fill-icon-disabled': disabled,
        })}
      />
      <input
        {...props}
        className={`w-full text-sm focus:outline-none placeholder:text-inputPlaceholder`}
      />
      {value && (
        <CloseIcon
          className={clsx('ml-auto fill-icon-primary cursor-pointer', {
            'fill-icon-disabled': disabled,
          })}
          onClick={() =>
            onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
          }
        />
      )}
    </div>
  );
};

export default SearchInput;
