import { useTranslation } from 'react-i18next';
import Button from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SignOut } from '../../assets/icons/signOut.svg';

const AutoLogout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onLoginAgain = () => {
    navigate('/login');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="flex flex-col items-center justify-center w-[435px]">
        <SignOut width={78} height={78} />
        <h1 className="text-center titleXXL text-text-primary p-spacing8">
          {t('autoLogout.title')}
        </h1>
        <p className="mb-8 text-center bodyS text-text-primary">
          {t('autoLogout.description')}
        </p>
        <Button fullWidth onPress={onLoginAgain}>
          {t('autoLogout.loginButton')}
        </Button>
      </div>
    </div>
  );
};

export default AutoLogout;
