import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import RightContainer from '../../components/login/rightContainer';
import Button from '../../components/button/button';
import RadioButtonGroup from '../../components/radioButtonGroup/radioButtonGroup';
import {
  DE_X,
  SELECTED_LANGAUGE_STORAGE_KEY,
  useLanguageOptions,
} from '../../constants/languageOptions';
import useLocalStorage from '../../helpers/hooks/useLocalStorage';
import { useEffect } from 'react';

const SelectLanguage = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage<string>(
    SELECTED_LANGAUGE_STORAGE_KEY,
    DE_X,
  );
  const languageOptions = useLanguageOptions();
  const navigate = useNavigate();

  const onNavigateToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-0 min-w-1280 p-0-16 m-0-auto">
      <div className="flex justify-center">
        <div className="self-center flex flex-col w-[392px]">
          <>
            <p className="text-center titleXXL mb-spacing10 text-text-primary">
              {t(
                'dashboard.tabs.profile.languageSetting.selectLanguageHeadline',
              )}
            </p>
            <div className="mb-spacing10">
              <RadioButtonGroup
                options={languageOptions}
                value={selectedLanguage}
                onChange={(v: string | number) =>
                  setSelectedLanguage(v as string)
                }
                rows
                onLanguageSelectScreen
              />
            </div>
            <Button onPress={onNavigateToLogin} data-cy="button-start">
              {t('start.start')}
            </Button>
            <p className="bodyS mt-spacing6 whitespace-pre-wrap h-[142px] text-text-tertiary text-center">
              {t('dashboard.tabs.profile.languageSetting.description')}
            </p>
          </>
        </div>
      </div>
      <RightContainer />
    </div>
  );
};

export default SelectLanguage;
