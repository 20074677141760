export default {
  translation: {
    appName: 'DocPad',
    localAdmin: 'local admin',
    general: {
      optional: 'optional',
      firstName: 'First Name',
      lastName: 'Last Name',
      birthdate: 'Birthdate',
      birthplace: 'Birthplace',
      email: 'E-mail',
      password: 'Password',
      LANR: 'LANR',
      department: 'Department',
      userRole: 'User Role',
      title: 'Title',
      gender: 'Gender',
      departmentName: 'Department Name',
      careSetting: 'Care Setting',
      supervisor: 'Supervisor',
      supervisee: 'Assistant',
      genderOptions: { divers: 'divers', female: 'female', male: 'male' },
      selectPlaceholder: 'select',
      selectedPlaceholder: 'selected',
      deactivated: 'deactivated',
      fieldIsMandatory: 'This field is mandatory in order to proceed.',
      wrongEmail: 'The email address provided is incorrect.',
      wrongBirthdate:
        'Date of birth should be greater than 18 years and less than 100 years.',
    },
    careSettings: {
      emergency_care: 'Emergency',
      in_care: 'In-Patient',
      out_care: 'Out-Patient',
    },
    buttons: {
      save: 'Save changes',
      cancel: 'Cancel',
      back: 'Go back',
      deactivate: 'Deactivate',
      reportError: 'Report this error',
      tryAgain: 'Try again',
      goHomepage: 'Go Homepage',
    },
    welcome: {
      body: 'We are happy that you can join us as a local administrator of your institution!\n\nClick the link below to setup your initial password. Then you will be redirect to the next screens to finish the login process.',
      button: 'Setup password',
      contact: 'If you need help, contact ',
      email: 'global.admin@docpad.com',
      greetings: 'Kind regards\nDocPad Team',
    },
    passwordSetup: {
      headline: 'Password Setup',
      password: 'Password',
      passwordPlaceholder: 'Enter password here',
      repeat: 'Re-enter password',
      repeatPlaceholder: 'Re-enter password here',
      button: 'Setup password',
    },
    start: {
      start: 'Start',
      headline: 'DocPad Local Admin Platform',
      body: "This is a service to manage your institution's departments and users. Add users, assign them roles and departments of your institution, change their data and deactivate them. Add, deactivate and edit departments in your institution. Revoke user consents to use the docpad application.",
      button: 'Go to login page',
      disclaimer:
        "*Our development team has carefully considered gendering and decided to use the generic masculine form with the inclusion of the gender-inclusive 'Binnen-I'. As an alternative option, we also provide a generically masculine language. We want to emphasize that our decision has no intention of discriminating against non-binary identities. We value and respect the diversity of our users and strive to offer inclusive and respectful language options.",
    },
    login: {
      headline: 'Login',
      email: 'E-mail',
      emailPlaceholder: 'Your e-mail here',
      password: 'Password',
      passwordPlaceholder: 'Enter password here',
      forgotPassword: 'Forgot password?',
      button: 'Login',
    },
    resetPassword: {
      headline: 'Reset password',
      body: 'Enter your email address, which is associated with your account.We will send a link to reset your password to this address.',
      email: 'E-mail',
      emailPlaceholder: 'Your e-mail here',
      resetButton: 'Reset password',
      loginButton: 'Login',
    },
    checkEmail: {
      headline: 'Check your email inbox',
      body: 'We have sent a link to reset your password to the email address you provided. Check your inbox and set a new password.\n\nIf the email address has not reached you:',
      li1: 'look in your SPAM folder\n',
      li2: 'check to assure that the e-mail address you entered is correct',
      button: 'Come back to login',
    },
    newPasswordSetup: {
      headline: 'New password setup',
      password: 'New password',
      passwordPlaceholder: 'Enter new password here',
      repeat: 'Repeat new password',
      repeatPlaceholder: 'Repeat new password here',
      button: 'Change password',
    },
    updatePassword: {
      headline: 'Your password has been updated.',
      body: 'Go to the login page and sign in to the DocPad App.',
      button: 'Go to the login page',
    },
    changePassword: {
      headline: 'Password Change',
      oldPassword: 'Old password',
      oldPasswordPlaceholder: 'Enter old password here',
      newPassword: 'New password',
      newPasswordPlaceholder: 'Enter new password here',
      repeatPassword: 'Repeat new password',
      repeatPasswordPlaceholder: 'Repeat new password here',
      button: 'Change password',
    },
    dashboard: {
      header: {
        institution: 'institution',
      },
      tabs: {
        profile: {
          headline: 'Profile Settings',
          profileInformation: 'Profile Information',
          changePassword: 'Change Password',
          passwordUpdated: 'Password updated',
          languageSetting: {
            english: '(EN) English',
            german: '(DE) German',
            selectLanguageHeadline: 'Select Language',
            headline: 'Language switcher and preferred language of gender',
            selectedLanguage: 'Selected language',
            languageGender: 'Preferred language of gender',
            germanBinary: 'German binary version',
            germanMale: 'German generic male version',
            description:
              'The application language can later be changed in the settings.',
          },
        },
        userManagement: {
          headline: 'User Management',
          list: 'List of users',
          search: 'Search user',
          button: 'Add new user',
          filterButtonShow: 'Show deactivated users',
          filterButtonHide: 'Hide deactivated users',
          addUser: {
            headline: 'Add new user',
            body: 'Fill in the fields below and add new user to your institution.',
            button: 'Add this user',
            success: 'User successfully added.',
            personalInformation: {
              headline: 'Personal Information',
              firstNamePlaceholder: 'Enter first name here',
              lastNamePlaceholder: 'Enter last name here',
              titlePlaceholder: 'Enter title here',
              genderPlaceholder: 'Select gender',
              birthdatePlaceholder: 'DD-MM-YYYY',
              birthplacePlaceholder: 'Enter Birthplace here',
            },
            professionalInformation: {
              headline: 'Professional Information',
              email: 'Email address',
              emailPlaceholder: 'Enter email address here',
              licenseNumber: 'License number',
              licenseNumberPlaceholder: 'Enter license number here',
              LANR: 'LANR',
              LANRPlaceholder: 'Enter LANR here',
              speciality: 'Speciality',
              specialityPlaceholder: 'Select speciality',
              telephone: 'Telefonnummer',
              telephonePlaceholder: 'Enter phone number here',
            },
            details: {
              headline: 'Details',
              department: 'Department',
              departmentPlaceholder: 'Select department',
              userRole: 'User role',
              userRolePlaceholder: 'Select user role',
              info: 'The role of a supervisor is responsible for signing the data created by an assistant',
            },
          },
          editUser: {
            headline: 'Edit user',
            body: 'Make changes and save them to update user data.',
            success: 'Changes successfully saved.',
          },
          userDetails: {
            headline: 'More details',
            body: 'Below you can see more information about the selected user.',
            deactivated: 'This user is deactivated.',
          },
          deactivation: {
            headline: 'Deactivation of the user',
            body: 'Are you sure you want to deactivate below user from your institution?',
            disclaimer:
              "*The user's data will not be removed from the places to which they was assigned.",
            success: 'Deactivation of the user successfully completed.',
          },
          listUpload: {
            headline: 'Upload list of users',
            hint: 'Click or drag a CSV-file to this area to upload.',
            error: 'Upload error. Please remove this file and try again.',
            button: 'Upload this file',
            notification: {
              error:
                'File import failed. The .CSV file had a different structure than required. Adjust the file structure and try again.',
              success: 'File successfully imported.',
            },
          },
        },
        departmentManagement: {
          headline: 'Department Management',
          list: 'List of departments',
          search: 'Search department',
          button: 'Add new department',
          editDisclaimer: {
            headline: 'No ability to edit this department',
            body: 'Edition of department is not possible when department is assigned to a doctor an does carry some episodes.',
          },
          addDepartment: {
            headline: 'Add the department',
            body: 'Fill in the fields below and add the department to your institution.',
            departmentNamePlaceholder: 'Enter department name here',
            careSettingPlaceholder: 'Select care setting',
            button: 'Add this department',
            success: 'Department successfully added.',
          },
          editDepartment: {
            headline: 'Edit the department',
            body: 'Edit previously entered data and save changes.',
            success: 'Changes to the department successfully saved.',
          },
          deactivation: {
            headline: 'Deactivation of the department',
            body: 'Are you sure you want to deactivate the department below from your institution?',
            disclaimer:
              "*The department's data will not be removed from the places to which it was assigned.",
            success: 'Deactivation of the department successfully completed.s',
          },
        },
        patientRemoval: {
          headline: 'Patient Removal',
          subline:
            'Information about revoking systemwide access for DocPad patient data',
          body: 'Revoking consent for system-wide access to patient data stored in the DocPad ecosystem can be done by the patient themselves by contacting the Data Security Officer by:',
          li1: 'email at:',
          li2: 'phone: +49 111111111',
          email: 'datasecurity.officer@docpad.com',
        },
      },
    },
    logout: {
      button: 'Logout',
      modalHeadline: 'Are you sure you want to log out?',
      modalInfo:
        'After logging out, it will be necessary to log back into the platform to use it as before.',
    },
    autoLogout: {
      title: 'You have been automatically log out.',
      description: 'We had to log you out of the app automatically for data security reasons because you had not taken any action for a long time.',
      loginButton: 'Log in again'
    },
    errorPages: {
      maintenance: {
        header: 'This page is under maintenance',
        detail: '',
        info: '',
      },
      construction: {
        header: 'This page is under construction',
        detail: 'There was some issues with syncing data.',
        info: 'Try again or contact technical support/local admin.',
      },
      noRights: {
        header: 'No rights to see this page',
        detail: 'You probably have no rights to see content on this page.',
        info: 'Try again or contact local admin.',
      },
      pageDoesntExist: {
        header: 'The page you are looking for doesn’t exist',
        detail:
          'This might, for instance, be because the page has been moved or removed, there is something wrong with the web address, a technical error occurred',
        info: 'Please, go back an try again later. If it still doesn’t work, contact us for help.',
      },
      cantBeReached: {
        header: 'The site can’t be reached.',
        detail: 'It takes too long to respond.',
        info: 'Check your internet connection and try again. If it still doesn’t work, contact us for help.',
      },
      unableToShow: {
        header: 'Sorry, we are unable to show this page at this time',
        detail: 'Something went wrong and we are working to fix the problem.',
        info: 'Try again, or welcome back later. If it still doesn’t work, please contact technical support.',
      },
    },
  },
};
