import { ReactNode } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Datepicker, { DatepickerType } from 'react-tailwindcss-datepicker';

export interface DateInputProps
  extends Omit<DatepickerType, 'value' | 'onChange'> {
  label?: ReactNode;
  messageType?: 'success' | 'error';
  message?: string;
  value?: Date;
  onChange: (value?: Date) => void;
}

const DateInput = ({
  label,
  messageType,
  message,
  value = new Date(),
  onChange,
  ...rest
}: DateInputProps) => {
  return (
    <div className="flex flex-col">
      <label
        className={clsx(
          'bodyS px-spacing5 py-spacing4 flex justify-self-start',
          {
            'text-text-primary': !messageType,
            'text-text-positive': messageType === 'success',
            'text-text-negative': messageType === 'error',
          },
        )}
      >
        {label}
      </label>
      <Datepicker
        displayFormat="YYYY-MM-DD"
        asSingle={true}
        {...rest}
        showShortcuts={false}
        value={{
          startDate: value,
          endDate: value,
        }}
        inputClassName={clsx(
          'px-spacing5 py-spacing6 text-sm border rounded-spacing4 focus:border-spacing2 focus:outline-none placeholder:text-inputPlaceholder bg-surface-primary focus:border-border-brandPrimary hover:border-border-brandSecondary active:border-border-brandPrimary w-full cy-datepicker',
          {
            'border-border-grayInputDefault': !messageType,
            'border-border-negative': messageType === 'error',
          },
        )}
        startFrom={value}
        onChange={(v) => {
          if (v?.startDate) {
            onChange(dayjs(v.startDate).toDate());
          }
        }}
      />
      {messageType && message && (
        <label
          className={clsx(
            'bodyS px-spacing5 py-spacing4 flex justify-self-start',
            {
              'text-text-positive': messageType === 'success',
              'text-text-negative': messageType === 'error',
            },
          )}
        >
          {message}
        </label>
      )}
    </div>
  );
};

export default DateInput;
