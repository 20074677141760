import axios from 'axios';
import { formatISO } from 'date-fns';

import {
  Configuration,
  KeycloakExtensionsApi,
  LocalAdminApi,
} from './open-api';
import keycloakConf from '../keycloak';
import { eventDispatcher } from '../helpers/customEvents';

const API_LOG_LEVEL: 'default' | 'verbose' = 'verbose';
const API_URL = process.env.REACT_APP_API_URL;

class ApiClient {
  public accessToken?: string;

  getAPIConfiguration = () => {
    const config = new Configuration({
      accessToken: this.accessToken,
    });

    config.isJsonMime = () => false;

    return config;
  };

  getAxiosInstance = () => {
    const axiosInstance = axios.create();

    axiosInstance.interceptors.request.use(
      (config) => {
        const ts = formatISO(new Date());
        if (API_LOG_LEVEL === 'verbose') {
          console.debug(
            `${ts} - request: ${config.method} ${config.url} ${JSON.stringify(
              config.headers,
            )} ${JSON.stringify(config.data)}`,
          );
        } else {
          console.debug(`${ts} - request: ${config.method} ${config.url}`);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        const ts = formatISO(new Date());
        if (API_LOG_LEVEL === 'verbose') {
          console.debug(
            `${ts} - response: [${response.status}] ${response.config.method} ${
              response.config.url
            } ${JSON.stringify(response.headers)} ${JSON.stringify(
              response.data,
            )}`,
          );
        } else {
          console.debug(
            `${ts} - response: [${response.status}] ${response.config.method} ${response.config.url}`,
          );
        }

        if (response.status >= 400) {
          eventDispatcher('badResponse', response.status);
        }

        return response;
      },
      (error) => {
        const ts = formatISO(new Date());

        if (API_LOG_LEVEL === 'verbose') {
          console.debug(
            `${ts} - response: [${error.response?.status}] ${
              error.config.method
            } ${error.config.url} ${JSON.stringify(
              error.headers,
            )} ${JSON.stringify(error.data)}`,
          );
        } else {
          console.debug(
            `${ts} - response: [${error.response?.status}] ${error.config.method} ${error.config.url}`,
          );
        }

        if (error.response?.status === 401) {
          eventDispatcher('logout');
        } else {
          eventDispatcher(
            'badResponse',
            error?.message ?? JSON.stringify(error),
          );
        }

        return Promise.reject(error);
      },
    );

    return axiosInstance;
  };

  public setToken = (value: string | undefined) => {
    this.accessToken = value;
  };

  public getAxiosConfig = () => {
    return { headers: { Authorization: `Bearer ${apiClient.accessToken}` } };
  };

  public getKeycloakUrl = (urlEnd: string) => {
    return `${keycloakConf.authServerUrl}admin/realms/${keycloakConf.realm}/${urlEnd}`;
  };

  public getLocalAdminAPI = (): LocalAdminApi => {
    return new LocalAdminApi(
      this.getAPIConfiguration(),
      API_URL,
      this.getAxiosInstance(),
    );
  };

  public getKeycloakExtensionsAPI = (): KeycloakExtensionsApi => {
    return new KeycloakExtensionsApi(
      this.getAPIConfiguration(),
      API_URL,
      this.getAxiosInstance(),
    );
  };
}

const apiClient = new ApiClient();

export default apiClient;
