export const displayString = (value: string | string[] | undefined) => {
  return value && value.length > 0 && Array.isArray(value)
    ? value.join(', ')
    : value && value.length > 0
      ? value
      : '-';
};

export const displayDepartments = (value: string[]) => {
  if (value) {
    return value.map((department) => department.split('/').pop());
  }
  return;
};

// get departments and user role a user is assigned to as strings to display in user table
export const groupsAsString = (groups: string[] = []) => {
  let filteredGroups;
  if (groups) {
    filteredGroups = groups.filter(
      (group) =>
        group.includes('/emergency_care') ||
        group.includes('/out_care') ||
        group.includes('/in_care'),
    );
  }

  const departmentsString = filteredGroups?.map((group) => {
    return group.substring(0, group.lastIndexOf('/'));
  });

  let roleString = filteredGroups?.map((group) => {
    return group.split('/').pop();
  });

  return {
    departments: departmentsString,
    role: roleString?.[0],
  };
};

export const getDepartments = (groups: string[] = []) => {
  return groups
    .filter(
      (group) =>
        group.includes('/emergency_care') ||
        group.includes('/out_care') ||
        group.includes('/in_care'),
    )
    .map((group) => {
      return group.replace(/\/(supervisor|supervisee|local_admins)/, '');
    });
};

export const getUserRole = (groups: string[] = []) => {
  const roles = groups
    ?.map((group) => {
      return group.split('/').pop();
    })
    .filter((role) => !!role);

  if (
    roles &&
    roles.some((role) => role === 'supervisor' || role === 'supervisee')
  ) {
    return roles[0];
  }

  return undefined;
};

export const getGroupsWithoutDepartments = (groups: string[]) => {
  let filteredGroups: string[] = [];
  if (groups) {
    filteredGroups = groups.filter(
      (group) =>
        !group.startsWith('/emergency_care') &&
        !group.startsWith('/out_care') &&
        !group.startsWith('/in_care'),
    );
  }
  return filteredGroups;
};
