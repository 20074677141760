import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import App from './App';
import keycloakConf from './keycloak';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ReactKeycloakProvider
    authClient={keycloakConf}
    initOptions={{
      flow: 'implicit',
      useNonce: true,
    }}
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>,
);
