import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevronUp.svg';
import { Dropdown, DropdownIndicator } from './styledComponents';
import { SpecialityItem } from '../../localAdmin-api/open-api';

const selectStyles = {
  control: (provided: any) => ({ ...provided, margin: 8 }),
  menu: () => ({ backgroundColor: 'white' }),
};

interface IMultiDropdown {
  id?: string;
  label: string;
  options: { value: string; label: string }[] | SpecialityItem[];
  placeholder?: string;
  disabled?: boolean;
  value?: string | string[] | undefined;
  onChange: (e: any) => void;
  isOptional?: boolean;
  isDepartment?: boolean;
}

const MultiDropdown = ({
  id,
  label,
  options,
  placeholder,
  disabled,
  value,
  onChange,
  isOptional,
  isDepartment,
}: IMultiDropdown) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    string | string[] | undefined
  >();

  useEffect(() => {
    if (value) {
      setSelectedOptions(value);
    }
  }, [value]);

  const toggleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const onSelect = (e: any) => {
    const itemValue = isDepartment ? e[0].value : e[0].label;
    // add or remove items from selectedOptions array
    if (Array.isArray(e)) {
      if (!selectedOptions?.includes(itemValue)) {
        // add items
        setSelectedOptions((prevState: any) => {
          let newState;
          if (Array.isArray(prevState)) {
            newState = [...prevState, itemValue];
          } else {
            newState = [itemValue];
          }
          onChange(newState);
          return newState;
        });
      } else {
        // remove items
        setSelectedOptions((prevState: any) => {
          let newState: any;
          if (Array.isArray(prevState)) {
            newState = [...prevState];
            newState.splice(prevState.indexOf(itemValue), 1);
          } else {
            newState = [];
          }

          onChange(newState);
          return newState;
        });
      }
    }
  };

  const MultiSelectOption = (props: any) => {
    // dropdown options as checkboxes
    return (
      <components.Option {...props}>
        <div className="flex border-b border-border-grayInputDefault p-spacing5 gap-spacing4">
          <button
            disabled={disabled}
            className={clsx(
              'w-5 h-5 border rounded-spacing2 flex justify-center items-center',
              {
                'bg-surface-disabled border-border-grayControlsDefaultDisabled':
                  disabled,
                'border-border-brandPrimary': selectedOptions?.includes(
                  isDepartment ? props.data.value : props.data.label,
                ),
              },
            )}
            name={props.data.label}
            value={isDepartment ? props.data.value : props.data.label}
            onClick={() =>
              onChange(isDepartment ? props.data.value : props.data.label)
            }
          >
            {selectedOptions?.includes(
              isDepartment ? props.data.value : props.data.label,
            ) && (
              <div className="w-4 h-4 bg-surface-brandPrimary rounded-spacing1" />
            )}
          </button>
          <label className="self-center bodyM">{props.label}</label>
        </div>
      </components.Option>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex bodyS px-spacing5 py-spacing4 justify-self-start">
        {label} {isOptional && `(${t('general.optional')})`}
      </div>

      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <button
            className="flex justify-between w-full border rounded-spacing2 border-border-grayInputDefault bodyM p-spacing5 bg-surface-primary hover:border-border-brandSecondary focus:bg-surface-primary active:border-border-brandPrimary"
            onClick={toggleOpen}
            id={id}
          >
            <div>
              {value && value.length > 0
                ? `${value.length} ${label} ${t('general.selectedPlaceholder')}`
                : placeholder || t('general.selectPlaceholder')}
            </div>
            <div className={`text-icon-primary`}>
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
          </button>
        }
      >
        <Select
          form={'none'}
          unstyled={true}
          isSearchable={true}
          classNames={{
            control: () =>
              `flex flex-row-reverse max-h-spacing11 items-center p-spacing5 rounded-spacing15 border 
              focus:border-spacing2 focus:border-border-dark bg-surface-primary border-border-grayInputDefault 
              hover:border-border-brandSecondary text-sm placeholder:text-inputPlaceholder`,
          }}
          isDisabled={disabled}
          openMenuOnClick={true}
          closeMenuOnSelect={false}
          isMulti={true}
          autoFocus
          backspaceRemovesValue={false}
          components={{
            Option: MultiSelectOption,
            DropdownIndicator,
            IndicatorSeparator: null,
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={onSelect}
          options={options}
          placeholder={t('dashboard.tabs.userManagement.search')}
          styles={selectStyles}
          tabSelectsValue={false}
          value={options.filter((option) => {
            return option.value === value;
          })}
        />
      </Dropdown>
    </div>
  );
};
export default MultiDropdown;
