import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as UploadFile } from '../../../assets/icons/uploadFile.svg';
import ModalScreen from './create-modal';
import { SearchInput } from '../../../components/input';
import Button from '../../../components/button/button';
import Table from '../../../components/table/table';
import Deactivation from '../deactivate-modal';
import ListUpload from './upload-modal';
import { useUsers } from '../../../localAdmin-api/queries/user';
import { matchSorter } from 'match-sorter';
import { displayDepartments, groupsAsString } from '../../../helpers/users';
import { useNavigate, useLocation } from 'react-router-dom';

export type UserModalType = 'add' | 'edit' | 'details';

const UserManagement = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<UserModalType>('add');
  const [deactivationVisible, setDeactivationVisible] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState<any>();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const tableHead = [
    'id',
    t('general.firstName'),
    t('general.lastName'),
    t('general.LANR'),
    t('general.department'),
    t('general.userRole'),
  ];

  const { data: users } = useUsers(true);
  const location = useLocation();
  const { disabledVisible } = location.state;

  useEffect(() => {
    if (location.pathname === '/dashboard/user-management') onCloseModal();
  }, [location.pathname]);

  useEffect(() => {
    if (users) {
      let filteredDisabled = users.filter((user) => {
        if (!disabledVisible) {
          return user.enabled;
        } else {
          return user;
        }
      });

      let test = filteredDisabled.map(
        ({ id, firstName, lastName, enabled, attributes, groups }) => {
          const groupsString = groupsAsString(groups!);

          return {
            id,
            firstName,
            lastName,
            LANR: attributes?.lanr,
            department: displayDepartments(
              groupsString.departments ?? [],
            )?.join(', '),
            userRole: t(`general.${groupsString.role}`),
            enabled,
          };
        },
      );

      let filteredData = matchSorter(test, searchQuery, {
        keys: ['firstName', 'lastName', 'LANR', 'department', 'userRole'],
        threshold: matchSorter.rankings.WORD_STARTS_WITH,
      });

      setUserData(filteredData);
    }
  }, [users, searchQuery, disabledVisible]);

  const openModal = () => {
    setModalVisible(true);
  };

  const onAdd = () => {
    openModal();
    setModalType('add');
    navigate('/dashboard/user-management/create', {
      state: { disabledVisible: false },
    });
  };

  const onEdit = (id: string) => {
    setUserId(id);
    openModal();
    setModalType('edit');
    navigate(`/dashboard/user-management/edit/${id}`, {
      state: { disabledVisible: false },
    });
  };

  const onOpenDetails = (id: string) => {
    setUserId(id);
    openModal();
    setModalType('details');
    navigate(`/dashboard/user-management/details/${id}`, {
      state: { disabledVisible: false },
    });
  };

  const onRemove = (id: string) => {
    setUserId(id);
    setDeactivationVisible(true);
  };

  const onCloseModal = () => {
    setModalVisible(false);
    setUserId('');
    navigate('/dashboard/user-management', {
      state: { disabledVisible: false },
    });
  };

  const onCloseDeactivation = () => {
    setDeactivationVisible(false);
    setUserId('');
  };

  const openUploadModal = () => {
    setUploadVisible(true);
  };

  const onCloseUploadModal = () => {
    setUploadVisible(false);
  };

  return (
    <>
      {!modalVisible ? (
        <>
          <div className="flex items-center justify-between mb-spacing6">
            <div>
              <p className="titleM w-[180px]">
                {t('dashboard.tabs.userManagement.list')}
              </p>
            </div>
            <div className="flex justify-between gap-spacing4">
              <SearchInput
                placeholder={t('dashboard.tabs.userManagement.search')}
                onChange={(event) => setSearchQuery(event.target.value)}
                value={searchQuery}
              />
              <Button onPress={onAdd} icon={<Plus />} data-cy="button-add-user">
                {t('dashboard.tabs.userManagement.button')}
              </Button>
              <Button
                onPress={openUploadModal}
                icon={<UploadFile />}
                backgroundStyle={'secondary'}
              />
            </div>
          </div>
          {userData && (
            <Table
              tableHead={tableHead}
              tableData={userData || [{}]}
              onEdit={(id) => onEdit(id)}
              onRemove={(id) => onRemove(id)}
              onOpenDetails={(id) => onOpenDetails(id)}
            />
          )}
        </>
      ) : (
        <ModalScreen onClose={onCloseModal} mode={modalType} userId={userId} />
      )}
      <Deactivation
        mode={'user'}
        open={deactivationVisible}
        onClose={onCloseDeactivation}
        userId={userId}
      />
      <ListUpload open={uploadVisible} onClose={onCloseUploadModal} />
    </>
  );
};

export default UserManagement;
