import React, { ReactElement } from 'react';
import { clsx } from 'clsx';

interface ITab {
  label: string;
  onPress?: () => void;
  icon?: ReactElement;
  active?: boolean;
}

const Tab: React.FC<ITab> = ({
  label,
  onPress = () => {},
  icon,
  active,
  ...rest
}) => {
  return (
    <button
      {...rest}
      value={label}
      className={clsx(
        'w-full flex flex-row bodyL hover:text-text-brandPrimary focus:font-semibold focus:text-text-brandPrimary',
        {
          'text-text-brandPrimary font-semibold': active,
          'text-text-primary': !active,
        },
      )}
      onClick={onPress}
      id={label.toLocaleLowerCase()}
    >
      {icon && <div className="mr-spacing4">{icon}</div>}
      {label}
    </button>
  );
};

export default Tab;
