import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';
import { clsx } from 'clsx';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  message?: string;
  messageType?: 'success' | 'error';
  value?: string;
}

const InputText = forwardRef((props: InputTextProps, ref: any) => {
  const { label, message, messageType, disabled, ...rest } = props;

  const id = label.split(' ').join('');

  return (
    <div className="flex flex-col">
      <label
        className={clsx(
          'bodyS px-spacing5 py-spacing4 flex justify-self-start',
          {
            'text-text-negative': messageType === 'error',
            'text-text-positive': messageType === 'success',
            'text-text-primary': !messageType,
          },
        )}
      >
        {label}
      </label>
      <input
        {...rest}
        ref={ref}
        disabled={disabled}
        id={id}
        className={clsx(
          'px-spacing5 py-spacing6 text-sm border rounded-spacing4 focus:border-spacing2 focus:outline-none border-border-grayInputDefault placeholder:text-inputPlaceholder bg-surface-primary focus:border-border-brandPrimary hover:border-border-brandSecondary active:border-border-brandPrimary',
          {
            'border-border-grayControlsDefaultDisabled text-text-disabled placeholder:text-disabled bg-surface-disabled':
              disabled,
            'border-border-negative': messageType === 'error',
            'border-border-positive': messageType === 'success',
          },
        )}
      />
      {!!(messageType || message) && (
        <div className="px-spacing5 py-spacing4 flex">
          {messageType === 'error' && (
            <div className="bg-surface-negative rounded-full w-spacing6 h-spacing6 content-center justify-center flex flex-wrap">
              <CloseIcon className="fill-icon-invert w-spacing5 h-spacing5" />
            </div>
          )}
          <div
            className={clsx('bodyS ml-spacing4', {
              'text-text-negative': messageType === 'error',
              'text-text-positive': messageType === 'success',
            })}
          >
            {message}
          </div>
        </div>
      )}
    </div>
  );
});

InputText.displayName = 'InputText';

export default InputText;
