import { useTranslation } from 'react-i18next';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as UserManagementIcon } from '../../assets/icons/userManagement.svg';
import { ReactComponent as DepartmentManagementIcon } from '../../assets/icons/departmentManagement.svg';
import { ReactComponent as PatientRemovalIcon } from '../../assets/icons/patientRemoval.svg';

export const useSidebarLinks = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('dashboard.tabs.profile.headline'),
      pathname: '/dashboard/profile',
      icon: <SettingsIcon />,
      dataCy: 'tab-profile',
    },
    {
      label: t('dashboard.tabs.userManagement.headline'),
      pathname: '/dashboard/user-management',
      icon: <UserManagementIcon />,
      dataCy: 'tab-userManagement',
      state: {
        disabledVisible: false,
      },
    },
    {
      label: t('dashboard.tabs.departmentManagement.headline'),
      pathname: '/dashboard/department-management',
      icon: <DepartmentManagementIcon />,
      dataCy: 'tab-departmentManagement',
    },
    {
      label: t('dashboard.tabs.patientRemoval.headline'),
      pathname: '/dashboard/patient-removal',
      icon: <PatientRemovalIcon />,
      dataCy: 'tab-patientRemoval',
    },
  ];
};
