import React, { useMemo, useState } from 'react';
import { ReactComponent as Pen } from '../../assets/icons/pen.svg';
import { ReactComponent as Remove } from '../../assets/icons/remove.svg';
import { ReactComponent as Sortable } from '../../assets/icons/sortable.svg';
import { ReactComponent as More } from '../../assets/icons/more.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Tooltip from '../tooltip/tooltip';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

interface ITable {
  tableHead: string[];
  tableData: Department[] | Physician[];
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  onOpenDetails?: (id: string) => void;
}

const Table: React.FC<ITable> = ({
  tableHead,
  tableData,
  onEdit,
  onRemove,
  onOpenDetails,
}) => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Department | Physician>();
  const columns =
    tableData && tableData.length > 0
      ? Object.keys(tableData?.[0]).map((column, index) =>
          columnHelper.accessor(
            Object.keys(tableData[0])[index] as keyof (Department | Physician),
            {
              header: () => <p>{tableHead[index]}</p>,
              cell: (info) => info.getValue(),
            },
          ),
        )
      : tableHead.map((column, index) =>
          columnHelper.accessor(
            tableHead[index] as keyof (Department | Physician),
            {
              header: () => <p>{tableHead[index]}</p>,
              cell: (info) => info.getValue(),
            },
          ),
        );

  const [sortedField, setSortedField] = useState('');
  const [sortingRule, setSortingRule] = useState('desc');

  const data = useMemo(() => {
    let sortedData = [...tableData];
    if (sortedField !== null) {
      sortedData.sort(
        (a: Department | Physician, b: Department | Physician) => {
          if (
            a[sortedField as keyof (Department | Physician)]! <
            b[sortedField as keyof (Department | Physician)]!
          ) {
            return sortingRule === 'desc' ? -1 : 1;
          }
          if (
            a[sortedField as keyof (Department | Physician)]! >
            b[sortedField as keyof (Department | Physician)]!
          ) {
            return sortingRule === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }

    return sortedData;
  }, [tableData, sortedField, sortingRule]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: { id: false, editable: false, enabled: false },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const onClickColHeader = (headerId: string) => {
    setSortedField(headerId);
    setSortingRule(
      headerId === sortedField
        ? sortingRule === 'asc'
          ? 'desc'
          : 'asc'
        : 'desc',
    );
  };

  return (
    <>
      <div className="max-h-[550px] overflow-auto">
        <table className="w-full text-left">
          <thead className="sticky top-0 border-b bg-surface-appBackground h-spacing11 border-border-brandPrimary">
            {table &&
              table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="titleXS">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={`p-spacing5 ${index === 0 && 'rounded-tl-lg'}`}
                    >
                      <button
                        className="flex gap-spacing2 whitespace-nowrap"
                        onClick={() => onClickColHeader(header.id)}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                        <Sortable />
                      </button>
                    </th>
                  ))}
                  <th key={`empty`} className="rounded-tr-lg"></th>
                </tr>
              ))}
          </thead>
          <tbody>
            {tableData &&
              table &&
              table.getRowModel().rows.map((row, index) => {
                return (
                  row.original.id?.length !== 0 && (
                    <tr
                      key={row.id}
                      className={clsx('', {
                        'border-b border-border-grayInputDefault':
                          index !== tableData.length - 1,
                        'bg-surface-disabled': row.original.enabled === false,
                      })}
                      data-cy="table-row-users"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-spacing5">
                          <p
                            className={clsx('bodyM', {
                              'text-text-disabled':
                                row.original.enabled === false,
                            })}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </p>
                        </td>
                      ))}
                      <td className="relative flex justify-end p-spacing5 gap-spacing6">
                        {onOpenDetails && (
                          <button
                            className="text-icon-brandPrimary"
                            onClick={() => onOpenDetails(row.original.id!)}
                            data-cy="table-row-users-details"
                          >
                            <More />
                          </button>
                        )}
                        {row.original.enabled !== false ? (
                          <>
                            <button
                              className={clsx('text-icon-brandPrimary', {
                                'text-icon-disabled':
                                  tableData[index].editable === false, // use endpoint for check here
                              })}
                              disabled={tableData[index].editable === false} // use endpoint for check here
                              onClick={() => onEdit(row.original.id!)}
                              data-tooltip-id={
                                tableData[index].editable === false // use endpoint for check here
                                  ? 'edit-disabled-tooltip'
                                  : ''
                              }
                              id={
                                'edit-button-' +
                                ('name' in row.original
                                  ? row.original.name
                                  : '')
                              }
                              data-cy="table-row-button-edit"
                            >
                              <Pen />
                            </button>
                            <button
                              className="text-icon-brandPrimary"
                              onClick={() => {
                                onRemove(row.original.id!);
                              }}
                              id={
                                'remove-button-' +
                                ('name' in row.original
                                  ? row.original.name
                                  : '')
                              }
                              data-cy="table-row-button-delete"
                            >
                              <Remove />
                            </button>
                          </>
                        ) : (
                          <div className="align-center bg-surface-primary rounded-spacing2 p-spacing3">
                            <p
                              className="font-semibold uppercase bodyXS text-text-tertiary"
                              data-cy="chip-deactivated"
                            >
                              {t('general.deactivated')}
                            </p>
                          </div>
                        )}
                        {!tableData[index].editable && (
                          <Tooltip id="edit-disabled-tooltip" />
                        )}
                      </td>
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="border-b border-border-brandPrimary" />
    </>
  );
};

export default Table;
