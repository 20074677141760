/* tslint:disable */
/* eslint-disable */
/**
 * Docpad WebService API on local
 * Docpad Backend on local, deployment date: 2024-07-22
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Pydantic model for all users attributes request that are optional
 * @export
 * @interface AttributesAll
 */
export interface AttributesAll {
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'approbationNumber'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'country'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'birthdate'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'gender'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'formatted'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'locality'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingCountry'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'languageSelection'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'lanr'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingLocality'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'title'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'facharztStatus'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingStreet'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingRegion'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingPostalCode'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'birthplace'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'phone'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'street'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'billingFormatted'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'region'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'postalCode'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'selectedDepartment'?: Array<string>;
    /**
     * 
     * @type {}
     * @memberof AttributesAll
     */
    'globalRole'?: Array<string>;
}
/**
 * Body system impact class
 * @export
 * @interface BodySystemImpact
 */
export interface BodySystemImpact {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof BodySystemImpact
     */
    'impacts': { [key: string]: number; };
}
/**
 * Body system type Enum class
 * @export
 * @enum {string}
 */

export const BodySystemType = {
    Metabolic: 'METABOLIC',
    Psychological: 'PSYCHOLOGICAL',
    Neurological: 'NEUROLOGICAL',
    Ocular: 'OCULAR',
    Ent: 'ENT',
    Pulmonary: 'PULMONARY',
    Cardiovascular: 'CARDIOVASCULAR',
    Gastrointestinal: 'GASTROINTESTINAL',
    Urogenital: 'UROGENITAL',
    Musculoskeletal: 'MUSCULOSKELETAL',
    Dermatological: 'DERMATOLOGICAL',
    Hematological: 'HEMATOLOGICAL'
} as const;

export type BodySystemType = typeof BodySystemType[keyof typeof BodySystemType];


/**
 * ChiefComplaintMatrix schema class
 * @export
 * @interface ChiefComplaintMatrix
 */
export interface ChiefComplaintMatrix {
    /**
     * 
     * @type {number}
     * @memberof ChiefComplaintMatrix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChiefComplaintMatrix
     */
    'name': string;
    /**
     * 
     * @type {Array<DiseaseBase>}
     * @memberof ChiefComplaintMatrix
     */
    'diseases'?: Array<DiseaseBase>;
}
/**
 * Representation of the patient in the database
 * @export
 * @interface DBPatient
 */
export interface DBPatient {
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'birthdate': string;
    /**
     * 
     * @type {Sex}
     * @memberof DBPatient
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'healthInsuranceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DBPatient
     */
    'mainDoctorId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DBPatient
     */
    'patientConsent'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DBPatient
     */
    'id': number;
    /**
     * Calculate the age of the patient based on their birthdate
     * @type {number}
     * @memberof DBPatient
     */
    'age': number;
}


/**
 * A response returning patient object and a message
 * @export
 * @interface DBPatientResponse
 */
export interface DBPatientResponse {
    /**
     * 
     * @type {string}
     * @memberof DBPatientResponse
     */
    'message': string;
    /**
     * 
     * @type {DBPatient}
     * @memberof DBPatientResponse
     */
    'patientData': DBPatient | null;
}
/**
 * A patient with some additional information related to the dashboard
 * @export
 * @interface DashboardPatient
 */
export interface DashboardPatient {
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'birthdate': string;
    /**
     * 
     * @type {Sex}
     * @memberof DashboardPatient
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'healthInsuranceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'mainDoctorId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardPatient
     */
    'patientConsent'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardPatient
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DashboardPatient
     */
    'lastVisit': string;
    /**
     * 
     * @type {NotificationsCount}
     * @memberof DashboardPatient
     */
    'notificationsCount': NotificationsCount;
    /**
     * Calculate the age of the patient based on their birthdate
     * @type {number}
     * @memberof DashboardPatient
     */
    'age': number;
}


/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'path': string;
    /**
     * Pydantic representation of a Keycloak Attributes
     * @type {{ [key: string]: any; }}
     * @memberof Department
     */
    'attributes': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Department
     */
    'realmRoles': Array<string>;
    /**
     * Pydantic representation of a Keycloak ClientRoles
     * @type {{ [key: string]: any; }}
     * @memberof Department
     */
    'clientRoles': { [key: string]: any; };
    /**
     * 
     * @type {Array<GroupRepresentation>}
     * @memberof Department
     */
    'subGroups': Array<GroupRepresentation>;
}
/**
 * Pydentic model for a Department
 * @export
 * @interface DepartmentRepresentation
 */
export interface DepartmentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DepartmentRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentRepresentation
     */
    'path': string;
    /**
     * Pydantic representation of a Keycloak Attributes
     * @type {{ [key: string]: any; }}
     * @memberof DepartmentRepresentation
     */
    'attributes': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentRepresentation
     */
    'realmRoles': Array<string>;
    /**
     * Pydantic representation of a Keycloak ClientRoles
     * @type {{ [key: string]: any; }}
     * @memberof DepartmentRepresentation
     */
    'clientRoles': { [key: string]: any; };
    /**
     * 
     * @type {Array<DepartmentRepresentation>}
     * @memberof DepartmentRepresentation
     */
    'subGroups': Array<DepartmentRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentRepresentation
     */
    'episodeCheck': boolean;
}
/**
 * Disease schema class
 * @export
 * @interface DiseaseBase
 */
export interface DiseaseBase {
    /**
     * 
     * @type {DiseaseICD10Code}
     * @memberof DiseaseBase
     */
    'icd10Code': DiseaseICD10Code;
    /**
     * 
     * @type {BodySystemImpact}
     * @memberof DiseaseBase
     */
    'bodySystem': BodySystemImpact;
    /**
     * 
     * @type {number}
     * @memberof DiseaseBase
     */
    'incidencePrevalence': number;
    /**
     * 
     * @type {EtiologyType}
     * @memberof DiseaseBase
     */
    'etiology': EtiologyType;
    /**
     * 
     * @type {RiskType}
     * @memberof DiseaseBase
     */
    'risk': RiskType;
    /**
     * 
     * @type {string}
     * @memberof DiseaseBase
     */
    'medicalDictionary': string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseBase
     */
    'shortDescription': string;
}


/**
 * Main DiseaseICD10Code class
 * @export
 * @interface DiseaseICD10Code
 */
export interface DiseaseICD10Code {
    /**
     * 
     * @type {number}
     * @memberof DiseaseICD10Code
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DiseaseICD10Code
     */
    'icd10CodeStr': string;
    /**
     * 
     * @type {DiseaseICD10Detail}
     * @memberof DiseaseICD10Code
     */
    'icd10Detail': DiseaseICD10Detail;
}
/**
 * DiseaseICD10Detail base Model.  This model represents the detailed information for a specific ICD-10 code, including the language-specific diagnosis and optional postfix that may differ between English and German systems. Each detail entry is linked to a common ICD-10 code but provides localized information to accommodate differences in medical terminology and classification between languages.  Attributes: ----------     icd_10_code_id (int): The unique identifier linking to the ICD-10 code.     language (str): The language code (e.g., \'de\' for German, \'en\' for English).     diagnosis (str): The full diagnosis name in the specified language.     short_diagnosis (Optional[str]): The short name of the diagnosis, if applicable.     postfix (Optional[str]): Additional postfix for the ICD-10 code to specify subcategories, if applicable.  Example: -------     icd_10_detail_en = DiseaseICD10Detail(         icd_10_code_id=1,         language=\'en\',         diagnosis=\'Acute Gastritis\',         short_diagnosis=\'Gastritis\',         postfix=\'.0\'     )      icd_10_detail_de = DiseaseICD10Detail(         icd_10_code_id=1,         language=\'de\',         diagnosis=\'Gastritis\',         short_diagnosis=\'Gastritis\',         postfix=\'.0\'     )
 * @export
 * @interface DiseaseICD10Detail
 */
export interface DiseaseICD10Detail {
    /**
     * 
     * @type {number}
     * @memberof DiseaseICD10Detail
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DiseaseICD10Detail
     */
    'icd10CodeId': number;
    /**
     * 
     * @type {LanguageType}
     * @memberof DiseaseICD10Detail
     */
    'language': LanguageType;
    /**
     * 
     * @type {string}
     * @memberof DiseaseICD10Detail
     */
    'diagnosis': string;
    /**
     * 
     * @type {string}
     * @memberof DiseaseICD10Detail
     */
    'shortDiagnosis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiseaseICD10Detail
     */
    'postfix'?: string | null;
}


/**
 * A response containing a message
 * @export
 * @interface DoctorsDashboardGenericResponse
 */
export interface DoctorsDashboardGenericResponse {
    /**
     * 
     * @type {string}
     * @memberof DoctorsDashboardGenericResponse
     */
    'message': string;
}
/**
 * A response containing all the departments in an institution and Doctor assigned Departments
 * @export
 * @interface DoctorsDashboardInstitutionsResponse
 */
export interface DoctorsDashboardInstitutionsResponse {
    /**
     * 
     * @type {Array<DoctorsSelectedDepartment>}
     * @memberof DoctorsDashboardInstitutionsResponse
     */
    'institutionDepartments': Array<DoctorsSelectedDepartment>;
    /**
     * 
     * @type {Array<DoctorsSelectedDepartment>}
     * @memberof DoctorsDashboardInstitutionsResponse
     */
    'doctorAssignedDepartments': Array<DoctorsSelectedDepartment>;
}
/**
 * A response containing the data relevant for the dashboard
 * @export
 * @interface DoctorsDashboardResponse
 */
export interface DoctorsDashboardResponse {
    /**
     * 
     * @type {Array<DashboardPatient>}
     * @memberof DoctorsDashboardResponse
     */
    'patients': Array<DashboardPatient>;
    /**
     * 
     * @type {Department}
     * @memberof DoctorsDashboardResponse
     */
    'department': Department;
    /**
     * 
     * @type {DoctorsDashboardResponseNotificationsCount}
     * @memberof DoctorsDashboardResponse
     */
    'notificationsCount': DoctorsDashboardResponseNotificationsCount;
}
/**
 * Calculate the global notifications count based on the counts of the patients
 * @export
 * @interface DoctorsDashboardResponseNotificationsCount
 */
export interface DoctorsDashboardResponseNotificationsCount {
    /**
     * 
     * @type {number}
     * @memberof DoctorsDashboardResponseNotificationsCount
     */
    'newLabResults'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorsDashboardResponseNotificationsCount
     */
    'newImagingResults'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorsDashboardResponseNotificationsCount
     */
    'newDocuments'?: number;
    /**
     * 
     * @type {number}
     * @memberof DoctorsDashboardResponseNotificationsCount
     */
    'signatureRequired'?: number;
}
/**
 * Selected department response model
 * @export
 * @interface DoctorsSelectedDepartment
 */
export interface DoctorsSelectedDepartment {
    /**
     * 
     * @type {string}
     * @memberof DoctorsSelectedDepartment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorsSelectedDepartment
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof DoctorsSelectedDepartment
     */
    'name': string;
}
/**
 * Emergency care current patients list fields
 * @export
 * @interface EmergencyRecord
 */
export interface EmergencyRecord {
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'arrivalTime': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'triggerForVisit': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'd1': string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'd2': string;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'vitalSigns': number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'laboratory': number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'imaging': number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'medication': number;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'release': string;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'patientId': number;
    /**
     * 
     * @type {LastEpisode}
     * @memberof EmergencyRecord
     */
    'lastEpisode': LastEpisode;
    /**
     * 
     * @type {number}
     * @memberof EmergencyRecord
     */
    'severity': number;
    /**
     * 
     * @type {string}
     * @memberof EmergencyRecord
     */
    'room': string;
}
/**
 * A response model for checking existing episodes for the department
 * @export
 * @interface EpisodeCheck
 */
export interface EpisodeCheck {
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeCheck
     */
    'check': boolean;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCheck
     */
    'department': string;
}
/**
 * Etiology type Enum class
 * @export
 * @enum {string}
 */

export const EtiologyType = {
    Genetic: 'GENETIC',
    Anatomic: 'ANATOMIC',
    Aging: 'AGING',
    Neoplastic: 'NEOPLASTIC',
    Toxic: 'TOXIC',
    Physiological: 'PHYSIOLOGICAL',
    Infectious: 'INFECTIOUS',
    Trauma: 'TRAUMA',
    Environmental: 'ENVIRONMENTAL',
    Idiopathic: 'IDIOPATHIC'
} as const;

export type EtiologyType = typeof EtiologyType[keyof typeof EtiologyType];


/**
 * Pydantic model for a Keycloak Group
 * @export
 * @interface GroupRepresentation
 */
export interface GroupRepresentation {
    /**
     * 
     * @type {string}
     * @memberof GroupRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GroupRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupRepresentation
     */
    'path': string;
    /**
     * Pydantic representation of a Keycloak Attributes
     * @type {{ [key: string]: any; }}
     * @memberof GroupRepresentation
     */
    'attributes': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupRepresentation
     */
    'realmRoles': Array<string>;
    /**
     * Pydantic representation of a Keycloak ClientRoles
     * @type {{ [key: string]: any; }}
     * @memberof GroupRepresentation
     */
    'clientRoles': { [key: string]: any; };
    /**
     * 
     * @type {Array<GroupRepresentation>}
     * @memberof GroupRepresentation
     */
    'subGroups': Array<GroupRepresentation>;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * Inpatient care current patients list fields
 * @export
 * @interface InPatientRecord
 */
export interface InPatientRecord {
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'arrivalTime': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'triggerForVisit': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'd1': string;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'd2': string;
    /**
     * 
     * @type {number}
     * @memberof InPatientRecord
     */
    'vitalSigns': number;
    /**
     * 
     * @type {number}
     * @memberof InPatientRecord
     */
    'laboratory': number;
    /**
     * 
     * @type {number}
     * @memberof InPatientRecord
     */
    'imaging': number;
    /**
     * 
     * @type {number}
     * @memberof InPatientRecord
     */
    'medication': number;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'release': string;
    /**
     * 
     * @type {number}
     * @memberof InPatientRecord
     */
    'patientId': number;
    /**
     * 
     * @type {LastEpisode}
     * @memberof InPatientRecord
     */
    'lastEpisode': LastEpisode;
    /**
     * 
     * @type {string}
     * @memberof InPatientRecord
     */
    'room': string;
}
/**
 * Language type
 * @export
 * @enum {string}
 */

export const LanguageType = {
    En: 'en',
    De: 'de'
} as const;

export type LanguageType = typeof LanguageType[keyof typeof LanguageType];


/**
 * Class to represent last episode details
 * @export
 * @interface LastEpisode
 */
export interface LastEpisode {
    /**
     * 
     * @type {string}
     * @memberof LastEpisode
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LastEpisode
     */
    'date': string;
}
/**
 * A count for possible notification types
 * @export
 * @interface NotificationsCount
 */
export interface NotificationsCount {
    /**
     * 
     * @type {number}
     * @memberof NotificationsCount
     */
    'newLabResults'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsCount
     */
    'newImagingResults'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsCount
     */
    'newDocuments'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsCount
     */
    'signatureRequired'?: number;
}
/**
 * Outpatient care current patients list fields
 * @export
 * @interface OutPatientRecord
 */
export interface OutPatientRecord {
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'arrivalTime': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'sex': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'triggerForVisit': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'd1': string;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'd2': string;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'vitalSigns': number;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'laboratory': number;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'imaging': number;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'medication': number;
    /**
     * 
     * @type {string}
     * @memberof OutPatientRecord
     */
    'release': string;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'patientId': number;
    /**
     * 
     * @type {LastEpisode}
     * @memberof OutPatientRecord
     */
    'lastEpisode': LastEpisode;
    /**
     * 
     * @type {number}
     * @memberof OutPatientRecord
     */
    'severity': number;
}
/**
 * Representation of the patient episodes
 * @export
 * @interface PatientEpisode
 */
export interface PatientEpisode {
    /**
     * 
     * @type {string}
     * @memberof PatientEpisode
     */
    'episodeId': string;
    /**
     * 
     * @type {string}
     * @memberof PatientEpisode
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof PatientEpisode
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof PatientEpisode
     */
    'careSystem': string;
}
/**
 * A model for updating a patient
 * @export
 * @interface PatientUpdate
 */
export interface PatientUpdate {
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'birthdate'?: string;
    /**
     * 
     * @type {Sex}
     * @memberof PatientUpdate
     */
    'sex'?: Sex | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'healthInsuranceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatientUpdate
     */
    'mainDoctorId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatientUpdate
     */
    'patientConsent'?: boolean | null;
}


/**
 * Risk type Enum class
 * @export
 * @enum {string}
 */

export const RiskType = {
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH'
} as const;

export type RiskType = typeof RiskType[keyof typeof RiskType];


/**
 * Represents biological sex
 * @export
 * @enum {string}
 */

export const Sex = {
    Male: 'male',
    Female: 'female'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];


/**
 * Pydantic model for specialist statuses response
 * @export
 * @interface SpecialityItem
 */
export interface SpecialityItem {
    /**
     * 
     * @type {string}
     * @memberof SpecialityItem
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SpecialityItem
     */
    'label': string;
}
/**
 * Pydantic model for a Keycloak User that has its roles/groups populated
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'createdTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'username': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'enabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'totp': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    'emailVerified': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email': string;
    /**
     * Pydantic representation of a Keycloak Attributes
     * @type {{ [key: string]: any; }}
     * @memberof UserInfo
     */
    'attributes'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'requiredActions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'realmRoles': Array<string>;
    /**
     * Pydantic representation of a Keycloak ClientRoles
     * @type {{ [key: string]: any; }}
     * @memberof UserInfo
     */
    'clientRoles': { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'groups': Array<string>;
}
/**
 * Pydantic model for a Keycloak User request that are optional
 * @export
 * @interface UserRepresentation
 */
export interface UserRepresentation {
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRepresentation
     */
    'enabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    'username'?: string | null;
    /**
     * 
     * @type {AttributesAll}
     * @memberof UserRepresentation
     */
    'attributes': AttributesAll;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRepresentation
     */
    'groups': Array<string>;
}
/**
 * Health insurance validation input
 * @export
 * @interface ValidInsuranceID
 */
export interface ValidInsuranceID {
    /**
     * 
     * @type {string}
     * @memberof ValidInsuranceID
     */
    'healthInsuranceNumber': string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * CurrentPatientsListApi - axios parameter creator
 * @export
 */
export const CurrentPatientsListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current patient list by department id
         * @summary Get Current Patient List
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPatientListCurrentPatientsListGet: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getCurrentPatientListCurrentPatientsListGet', 'departmentId', departmentId)
            const localVarPath = `/current_patients_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (departmentId !== undefined) {
                localVarQueryParameter['department_id'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CurrentPatientsListApi - functional programming interface
 * @export
 */
export const CurrentPatientsListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CurrentPatientsListApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current patient list by department id
         * @summary Get Current Patient List
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPatientListCurrentPatientsListGet(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPatientListCurrentPatientsListGet(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CurrentPatientsListApi - factory interface
 * @export
 */
export const CurrentPatientsListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CurrentPatientsListApiFp(configuration)
    return {
        /**
         * Get current patient list by department id
         * @summary Get Current Patient List
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPatientListCurrentPatientsListGet(departmentId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCurrentPatientListCurrentPatientsListGet(departmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CurrentPatientsListApi - object-oriented interface
 * @export
 * @class CurrentPatientsListApi
 * @extends {BaseAPI}
 */
export class CurrentPatientsListApi extends BaseAPI {
    /**
     * Get current patient list by department id
     * @summary Get Current Patient List
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CurrentPatientsListApi
     */
    public getCurrentPatientListCurrentPatientsListGet(departmentId: string, options?: AxiosRequestConfig) {
        return CurrentPatientsListApiFp(this.configuration).getCurrentPatientListCurrentPatientsListGet(departmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DiagnosesApi - axios parameter creator
 * @export
 */
export const DiagnosesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint retrieves a list of diagnoses for a given complaint ID. It currently uses mocked data but will be connected to a real database in the future. Replace the mocked function with a real database function once the database is populated.
         * @summary Read Diagnoses By Complaint
         * @param {number} complaintId 
         * @param {LanguageType} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet: async (complaintId: number, language?: LanguageType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintId' is not null or undefined
            assertParamExists('readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet', 'complaintId', complaintId)
            const localVarPath = `/diagnoses/diagnoses_matrix/{complaint_id}`
                .replace(`{${"complaint_id"}}`, encodeURIComponent(String(complaintId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiagnosesApi - functional programming interface
 * @export
 */
export const DiagnosesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiagnosesApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint retrieves a list of diagnoses for a given complaint ID. It currently uses mocked data but will be connected to a real database in the future. Replace the mocked function with a real database function once the database is populated.
         * @summary Read Diagnoses By Complaint
         * @param {number} complaintId 
         * @param {LanguageType} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId: number, language?: LanguageType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChiefComplaintMatrix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DiagnosesApi - factory interface
 * @export
 */
export const DiagnosesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiagnosesApiFp(configuration)
    return {
        /**
         * This endpoint retrieves a list of diagnoses for a given complaint ID. It currently uses mocked data but will be connected to a real database in the future. Replace the mocked function with a real database function once the database is populated.
         * @summary Read Diagnoses By Complaint
         * @param {number} complaintId 
         * @param {LanguageType} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId: number, language?: LanguageType, options?: any): AxiosPromise<ChiefComplaintMatrix> {
            return localVarFp.readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiagnosesApi - object-oriented interface
 * @export
 * @class DiagnosesApi
 * @extends {BaseAPI}
 */
export class DiagnosesApi extends BaseAPI {
    /**
     * This endpoint retrieves a list of diagnoses for a given complaint ID. It currently uses mocked data but will be connected to a real database in the future. Replace the mocked function with a real database function once the database is populated.
     * @summary Read Diagnoses By Complaint
     * @param {number} complaintId 
     * @param {LanguageType} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiagnosesApi
     */
    public readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId: number, language?: LanguageType, options?: AxiosRequestConfig) {
        return DiagnosesApiFp(this.configuration).readDiagnosesByComplaintDiagnosesDiagnosesMatrixComplaintIdGet(complaintId, language, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DoctorsDashboardApi - axios parameter creator
 * @export
 */
export const DoctorsDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve doctor assigned departments and institution departments
         * @summary Get All Departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentsDashboardsDoctorAllDepartmentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/doctor/all_departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to get all relevant data for doctor\'s dashboard
         * @summary Get Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDashboardsPatientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve selected department of doctor
         * @summary Get Doctor Selected Department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboards/doctor/selected_department`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update doctor selected department
         * @summary Patch Doctor Selected Department
         * @param {string} departmentId 
         * @param {string | null} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut: async (departmentId: string, reason?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut', 'departmentId', departmentId)
            const localVarPath = `/dashboards/doctor/department/{department_id}/selected_department`
                .replace(`{${"department_id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DoctorsDashboardApi - functional programming interface
 * @export
 */
export const DoctorsDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DoctorsDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve doctor assigned departments and institution departments
         * @summary Get All Departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorsDashboardInstitutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint to get all relevant data for doctor\'s dashboard
         * @summary Get Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardDashboardsPatientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorsDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardDashboardsPatientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve selected department of doctor
         * @summary Get Doctor Selected Department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorsSelectedDepartment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update doctor selected department
         * @summary Patch Doctor Selected Department
         * @param {string} departmentId 
         * @param {string | null} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId: string, reason?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoctorsDashboardGenericResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId, reason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DoctorsDashboardApi - factory interface
 * @export
 */
export const DoctorsDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DoctorsDashboardApiFp(configuration)
    return {
        /**
         * Retrieve doctor assigned departments and institution departments
         * @summary Get All Departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options?: any): AxiosPromise<DoctorsDashboardInstitutionsResponse> {
            return localVarFp.getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to get all relevant data for doctor\'s dashboard
         * @summary Get Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardDashboardsPatientsGet(options?: any): AxiosPromise<DoctorsDashboardResponse> {
            return localVarFp.getDashboardDashboardsPatientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve selected department of doctor
         * @summary Get Doctor Selected Department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options?: any): AxiosPromise<DoctorsSelectedDepartment> {
            return localVarFp.getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update doctor selected department
         * @summary Patch Doctor Selected Department
         * @param {string} departmentId 
         * @param {string | null} [reason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId: string, reason?: string | null, options?: any): AxiosPromise<DoctorsDashboardGenericResponse> {
            return localVarFp.patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId, reason, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DoctorsDashboardApi - object-oriented interface
 * @export
 * @class DoctorsDashboardApi
 * @extends {BaseAPI}
 */
export class DoctorsDashboardApi extends BaseAPI {
    /**
     * Retrieve doctor assigned departments and institution departments
     * @summary Get All Departments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsDashboardApi
     */
    public getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options?: AxiosRequestConfig) {
        return DoctorsDashboardApiFp(this.configuration).getAllDepartmentsDashboardsDoctorAllDepartmentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to get all relevant data for doctor\'s dashboard
     * @summary Get Dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsDashboardApi
     */
    public getDashboardDashboardsPatientsGet(options?: AxiosRequestConfig) {
        return DoctorsDashboardApiFp(this.configuration).getDashboardDashboardsPatientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve selected department of doctor
     * @summary Get Doctor Selected Department
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsDashboardApi
     */
    public getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options?: AxiosRequestConfig) {
        return DoctorsDashboardApiFp(this.configuration).getDoctorSelectedDepartmentDashboardsDoctorSelectedDepartmentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update doctor selected department
     * @summary Patch Doctor Selected Department
     * @param {string} departmentId 
     * @param {string | null} [reason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DoctorsDashboardApi
     */
    public patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId: string, reason?: string | null, options?: AxiosRequestConfig) {
        return DoctorsDashboardApiFp(this.configuration).patchDoctorSelectedDepartmentDashboardsDoctorDepartmentDepartmentIdSelectedDepartmentPut(departmentId, reason, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KeycloakExtensionsApi - axios parameter creator
 * @export
 */
export const KeycloakExtensionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Edit/delete user info and groups assigned as well
         * @summary Edit User
         * @param {string} userId 
         * @param {UserRepresentation} userRepresentation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserKeycloakExtensionsUsersEditUserIdPut: async (userId: string, userRepresentation: UserRepresentation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('editUserKeycloakExtensionsUsersEditUserIdPut', 'userId', userId)
            // verify required parameter 'userRepresentation' is not null or undefined
            assertParamExists('editUserKeycloakExtensionsUsersEditUserIdPut', 'userRepresentation', userRepresentation)
            const localVarPath = `/keycloak_extensions/users/edit/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRepresentation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if the given department already has episodes
         * @summary Episode Check
         * @param {string} department 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        episodeCheckKeycloakExtensionsEpisodeCheckGet: async (department: string, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'department' is not null or undefined
            assertParamExists('episodeCheckKeycloakExtensionsEpisodeCheckGet', 'department', department)
            const localVarPath = `/keycloak_extensions/episode_check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get departments\' hierarchy from keycloak with all their subgroups
         * @summary Get Department Hierarchy
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentHierarchyKeycloakExtensionsDepartmentsGet: async (configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keycloak_extensions/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return logged-in user\'s info with populated group and roles
         * @summary Get My User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUserKeycloakExtensionsUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keycloak_extensions/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return single user with populated group and roles
         * @summary Get Single User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUserKeycloakExtensionsUsersUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSingleUserKeycloakExtensionsUsersUserIdGet', 'userId', userId)
            const localVarPath = `/keycloak_extensions/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users from the keycloak and populate their groups and roles lists
         * @summary Get Users
         * @param {boolean} [onlyPhysicians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersKeycloakExtensionsUsersGet: async (onlyPhysicians?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/keycloak_extensions/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (onlyPhysicians !== undefined) {
                localVarQueryParameter['only_physicians'] = onlyPhysicians;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeycloakExtensionsApi - functional programming interface
 * @export
 */
export const KeycloakExtensionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeycloakExtensionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Edit/delete user info and groups assigned as well
         * @summary Edit User
         * @param {string} userId 
         * @param {UserRepresentation} userRepresentation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserKeycloakExtensionsUsersEditUserIdPut(userId: string, userRepresentation: UserRepresentation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUserKeycloakExtensionsUsersEditUserIdPut(userId, userRepresentation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if the given department already has episodes
         * @summary Episode Check
         * @param {string} department 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async episodeCheckKeycloakExtensionsEpisodeCheckGet(department: string, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EpisodeCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.episodeCheckKeycloakExtensionsEpisodeCheckGet(department, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get departments\' hierarchy from keycloak with all their subgroups
         * @summary Get Department Hierarchy
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DepartmentRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return logged-in user\'s info with populated group and roles
         * @summary Get My User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyUserKeycloakExtensionsUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyUserKeycloakExtensionsUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return single user with populated group and roles
         * @summary Get Single User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleUserKeycloakExtensionsUsersUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleUserKeycloakExtensionsUsersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all users from the keycloak and populate their groups and roles lists
         * @summary Get Users
         * @param {boolean} [onlyPhysicians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersKeycloakExtensionsUsersGet(onlyPhysicians?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersKeycloakExtensionsUsersGet(onlyPhysicians, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeycloakExtensionsApi - factory interface
 * @export
 */
export const KeycloakExtensionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeycloakExtensionsApiFp(configuration)
    return {
        /**
         * Edit/delete user info and groups assigned as well
         * @summary Edit User
         * @param {string} userId 
         * @param {UserRepresentation} userRepresentation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserKeycloakExtensionsUsersEditUserIdPut(userId: string, userRepresentation: UserRepresentation, options?: any): AxiosPromise<void> {
            return localVarFp.editUserKeycloakExtensionsUsersEditUserIdPut(userId, userRepresentation, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if the given department already has episodes
         * @summary Episode Check
         * @param {string} department 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        episodeCheckKeycloakExtensionsEpisodeCheckGet(department: string, configNum?: number, options?: any): AxiosPromise<EpisodeCheck> {
            return localVarFp.episodeCheckKeycloakExtensionsEpisodeCheckGet(department, configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Get departments\' hierarchy from keycloak with all their subgroups
         * @summary Get Department Hierarchy
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum?: number, options?: any): AxiosPromise<Array<DepartmentRepresentation>> {
            return localVarFp.getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Return logged-in user\'s info with populated group and roles
         * @summary Get My User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyUserKeycloakExtensionsUserGet(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getMyUserKeycloakExtensionsUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Return single user with populated group and roles
         * @summary Get Single User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleUserKeycloakExtensionsUsersUserIdGet(userId: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getSingleUserKeycloakExtensionsUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users from the keycloak and populate their groups and roles lists
         * @summary Get Users
         * @param {boolean} [onlyPhysicians] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersKeycloakExtensionsUsersGet(onlyPhysicians?: boolean, options?: any): AxiosPromise<Array<UserInfo>> {
            return localVarFp.getUsersKeycloakExtensionsUsersGet(onlyPhysicians, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeycloakExtensionsApi - object-oriented interface
 * @export
 * @class KeycloakExtensionsApi
 * @extends {BaseAPI}
 */
export class KeycloakExtensionsApi extends BaseAPI {
    /**
     * Edit/delete user info and groups assigned as well
     * @summary Edit User
     * @param {string} userId 
     * @param {UserRepresentation} userRepresentation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public editUserKeycloakExtensionsUsersEditUserIdPut(userId: string, userRepresentation: UserRepresentation, options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).editUserKeycloakExtensionsUsersEditUserIdPut(userId, userRepresentation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if the given department already has episodes
     * @summary Episode Check
     * @param {string} department 
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public episodeCheckKeycloakExtensionsEpisodeCheckGet(department: string, configNum?: number, options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).episodeCheckKeycloakExtensionsEpisodeCheckGet(department, configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get departments\' hierarchy from keycloak with all their subgroups
     * @summary Get Department Hierarchy
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum?: number, options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).getDepartmentHierarchyKeycloakExtensionsDepartmentsGet(configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return logged-in user\'s info with populated group and roles
     * @summary Get My User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public getMyUserKeycloakExtensionsUserGet(options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).getMyUserKeycloakExtensionsUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return single user with populated group and roles
     * @summary Get Single User
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public getSingleUserKeycloakExtensionsUsersUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).getSingleUserKeycloakExtensionsUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users from the keycloak and populate their groups and roles lists
     * @summary Get Users
     * @param {boolean} [onlyPhysicians] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakExtensionsApi
     */
    public getUsersKeycloakExtensionsUsersGet(onlyPhysicians?: boolean, options?: AxiosRequestConfig) {
        return KeycloakExtensionsApiFp(this.configuration).getUsersKeycloakExtensionsUsersGet(onlyPhysicians, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocalAdminApi - axios parameter creator
 * @export
 */
export const LocalAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the list of allowed specialist statuses
         * @summary Get Medical Specialities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalSpecialitiesLocalAdminSpecialistStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/local_admin/specialist_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalAdminApi - functional programming interface
 * @export
 */
export const LocalAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the list of allowed specialist statuses
         * @summary Get Medical Specialities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalAdminApi - factory interface
 * @export
 */
export const LocalAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalAdminApiFp(configuration)
    return {
        /**
         * Get the list of allowed specialist statuses
         * @summary Get Medical Specialities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options?: any): AxiosPromise<Array<SpecialityItem>> {
            return localVarFp.getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalAdminApi - object-oriented interface
 * @export
 * @class LocalAdminApi
 * @extends {BaseAPI}
 */
export class LocalAdminApi extends BaseAPI {
    /**
     * Get the list of allowed specialist statuses
     * @summary Get Medical Specialities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalAdminApi
     */
    public getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options?: AxiosRequestConfig) {
        return LocalAdminApiFp(this.configuration).getMedicalSpecialitiesLocalAdminSpecialistStatusGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create patient in the database
         * @summary Create Patient
         * @param {boolean} patientConsent 
         * @param {ValidInsuranceID} validInsuranceID 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPatientCreatePost: async (patientConsent: boolean, validInsuranceID: ValidInsuranceID, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientConsent' is not null or undefined
            assertParamExists('createPatientPatientCreatePost', 'patientConsent', patientConsent)
            // verify required parameter 'validInsuranceID' is not null or undefined
            assertParamExists('createPatientPatientCreatePost', 'validInsuranceID', validInsuranceID)
            const localVarPath = `/patient/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (patientConsent !== undefined) {
                localVarQueryParameter['patient_consent'] = patientConsent;
            }

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validInsuranceID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the patient from the database
         * @summary Delete Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientPatientPatientIdDeleteDelete: async (patientId: number, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('deletePatientPatientPatientIdDeleteDelete', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}/delete`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the patient from the episode mock
         * @summary Get Patient Episodes
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesPatientPatientEpisodesPatientIdGet: async (patientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('getPatientEpisodesPatientPatientEpisodesPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patient/patient_episodes/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the patient from the database
         * @summary Read Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPatientPatientPatientIdGet: async (patientId: number, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('readPatientPatientPatientIdGet', 'patientId', patientId)
            const localVarPath = `/patient/{patient_id}`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the patient in the database
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientPatientIdUpdatePut: async (patientId: number, patientUpdate: PatientUpdate, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('updatePatientPatientPatientIdUpdatePut', 'patientId', patientId)
            // verify required parameter 'patientUpdate' is not null or undefined
            assertParamExists('updatePatientPatientPatientIdUpdatePut', 'patientUpdate', patientUpdate)
            const localVarPath = `/patient/{patient_id}/update`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patientUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate patient in PMS and check if it exists in DB
         * @summary Validate Patient
         * @param {string} healthInsuranceNumber Valid health insurance number
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePatientPatientValidatePatientGet: async (healthInsuranceNumber: string, configNum?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'healthInsuranceNumber' is not null or undefined
            assertParamExists('validatePatientPatientValidatePatientGet', 'healthInsuranceNumber', healthInsuranceNumber)
            const localVarPath = `/patient/validate_patient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2", [], configuration)

            if (healthInsuranceNumber !== undefined) {
                localVarQueryParameter['health_insurance_number'] = healthInsuranceNumber;
            }

            if (configNum !== undefined) {
                localVarQueryParameter['config_num'] = configNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * Create patient in the database
         * @summary Create Patient
         * @param {boolean} patientConsent 
         * @param {ValidInsuranceID} validInsuranceID 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPatientPatientCreatePost(patientConsent: boolean, validInsuranceID: ValidInsuranceID, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DBPatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPatientPatientCreatePost(patientConsent, validInsuranceID, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the patient from the database
         * @summary Delete Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePatientPatientPatientIdDeleteDelete(patientId: number, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePatientPatientPatientIdDeleteDelete(patientId, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the patient from the episode mock
         * @summary Get Patient Episodes
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PatientEpisode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the patient from the database
         * @summary Read Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPatientPatientPatientIdGet(patientId: number, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DBPatient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPatientPatientPatientIdGet(patientId, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the patient in the database
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePatientPatientPatientIdUpdatePut(patientId: number, patientUpdate: PatientUpdate, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DBPatient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePatientPatientPatientIdUpdatePut(patientId, patientUpdate, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate patient in PMS and check if it exists in DB
         * @summary Validate Patient
         * @param {string} healthInsuranceNumber Valid health insurance number
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePatientPatientValidatePatientGet(healthInsuranceNumber: string, configNum?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DBPatientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validatePatientPatientValidatePatientGet(healthInsuranceNumber, configNum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * Create patient in the database
         * @summary Create Patient
         * @param {boolean} patientConsent 
         * @param {ValidInsuranceID} validInsuranceID 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPatientPatientCreatePost(patientConsent: boolean, validInsuranceID: ValidInsuranceID, configNum?: number, options?: any): AxiosPromise<DBPatientResponse> {
            return localVarFp.createPatientPatientCreatePost(patientConsent, validInsuranceID, configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the patient from the database
         * @summary Delete Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePatientPatientPatientIdDeleteDelete(patientId: number, configNum?: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePatientPatientPatientIdDeleteDelete(patientId, configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the patient from the episode mock
         * @summary Get Patient Episodes
         * @param {number} patientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId: number, options?: any): AxiosPromise<Array<PatientEpisode>> {
            return localVarFp.getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the patient from the database
         * @summary Read Patient
         * @param {number} patientId 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPatientPatientPatientIdGet(patientId: number, configNum?: number, options?: any): AxiosPromise<DBPatient> {
            return localVarFp.readPatientPatientPatientIdGet(patientId, configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the patient in the database
         * @summary Update Patient
         * @param {number} patientId 
         * @param {PatientUpdate} patientUpdate 
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePatientPatientPatientIdUpdatePut(patientId: number, patientUpdate: PatientUpdate, configNum?: number, options?: any): AxiosPromise<DBPatient> {
            return localVarFp.updatePatientPatientPatientIdUpdatePut(patientId, patientUpdate, configNum, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate patient in PMS and check if it exists in DB
         * @summary Validate Patient
         * @param {string} healthInsuranceNumber Valid health insurance number
         * @param {number} [configNum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePatientPatientValidatePatientGet(healthInsuranceNumber: string, configNum?: number, options?: any): AxiosPromise<DBPatientResponse> {
            return localVarFp.validatePatientPatientValidatePatientGet(healthInsuranceNumber, configNum, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI {
    /**
     * Create patient in the database
     * @summary Create Patient
     * @param {boolean} patientConsent 
     * @param {ValidInsuranceID} validInsuranceID 
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public createPatientPatientCreatePost(patientConsent: boolean, validInsuranceID: ValidInsuranceID, configNum?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).createPatientPatientCreatePost(patientConsent, validInsuranceID, configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the patient from the database
     * @summary Delete Patient
     * @param {number} patientId 
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public deletePatientPatientPatientIdDeleteDelete(patientId: number, configNum?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).deletePatientPatientPatientIdDeleteDelete(patientId, configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the patient from the episode mock
     * @summary Get Patient Episodes
     * @param {number} patientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).getPatientEpisodesPatientPatientEpisodesPatientIdGet(patientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the patient from the database
     * @summary Read Patient
     * @param {number} patientId 
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public readPatientPatientPatientIdGet(patientId: number, configNum?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).readPatientPatientPatientIdGet(patientId, configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the patient in the database
     * @summary Update Patient
     * @param {number} patientId 
     * @param {PatientUpdate} patientUpdate 
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public updatePatientPatientPatientIdUpdatePut(patientId: number, patientUpdate: PatientUpdate, configNum?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).updatePatientPatientPatientIdUpdatePut(patientId, patientUpdate, configNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate patient in PMS and check if it exists in DB
     * @summary Validate Patient
     * @param {string} healthInsuranceNumber Valid health insurance number
     * @param {number} [configNum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public validatePatientPatientValidatePatientGet(healthInsuranceNumber: string, configNum?: number, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).validatePatientPatientValidatePatientGet(healthInsuranceNumber, configNum, options).then((request) => request(this.axios, this.basePath));
    }
}



