import { FC, ReactElement, useCallback } from 'react';
import { clsx } from 'clsx';

interface IRadioButton {
  options: {
    value: string | number;
    label: string;
    icon?: ReactElement;
  }[];
  onChange: (value: string | number) => void;
  value?: string | number;
  disabled?: boolean;
  rows?: boolean;
  onLanguageSelectScreen?: boolean;
}

const RadioButton: FC<IRadioButton> = ({
  options,
  onChange,
  disabled,
  rows,
  onLanguageSelectScreen,
  value,
}) => {
  const renderOptions = useCallback(() => {
    return options.map((aOption, index) => {
      const isChecked = value === aOption.value;

      return (
        <div
          key={aOption.value}
          className={clsx('flex items-center', {
            'justify-center': !rows,
            'mb-spacing8':
              onLanguageSelectScreen && index !== options.length - 1,
            'mb-spacing6': index !== options.length - 1,
          })}
        >
          {!rows && aOption.label}
          <button
            disabled={disabled}
            className={clsx(
              'w-5 h-5 border rounded-spacing6 flex justify-center items-center',
              {
                'mr-2': rows,
                'bg-surface-disabled border-border-grayControlsDefaultDisabled':
                  disabled,
                'border-border-brandPrimary': isChecked,
                'border-border-dark': !isChecked,
              },
            )}
            name={aOption.label}
            value={aOption.value}
            onClick={() => onChange(aOption.value)}
          >
            {isChecked && (
              <div
                className={clsx(
                  'w-4 h-4 bg-surface-brandPrimary rounded-spacing6',
                  { 'bg-surface-selectedDisabled': disabled },
                )}
              />
            )}
          </button>
          {rows && <div className="mr-spacing4">{aOption.icon}</div>}
          {rows && aOption.label}
        </div>
      );
    });
  }, [disabled, options]);

  return (
    <div className={clsx('flex', { 'flex-col': rows })}>{renderOptions()}</div>
  );
};

export default RadioButton;
