import { ReactNode } from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import Button from '../button/button';

interface IErrorDialog {
  isOpen: boolean;
  onClose: () => void;
  errorTitle: ReactNode;
  description: ReactNode;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
}

const ErrorDialog = ({
  isOpen,
  onClose,
  errorTitle,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  onSecondaryButtonClick,
}: IErrorDialog) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="elevation flex-row flex absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[816px] p-spacing10 rounded-spacing6 bg-surface-primary outline-none"
    >
      <div className="bg-surface-negative rounded-full w-spacing11 h-spacing11 content-center justify-center flex flex-wrap">
        <CloseIcon className="fill-icon-invert w-spacing10 h-spacing10" />
      </div>
      <div className="ml-spacing6 flex flex-col">
        {typeof errorTitle === 'string' ? (
          <span className="titleL text-text-primary mb-spacing8 font-semibold">
            {errorTitle}
          </span>
        ) : (
          errorTitle
        )}
        {typeof description === 'string' ? (
          <span className="bodyL text-text-primary mb-spacing8">
            {description}
          </span>
        ) : (
          <div className="bodyL text-text-primary mb-spacing8">
            {description}
          </div>
        )}
        <div className="flex flex-row">
          <Button onPress={onClose}>{primaryButtonLabel}</Button>
          {!!secondaryButtonLabel && (
            <div className="ml-spacing8">
              <Button
                backgroundStyle="tertiary"
                onPress={onSecondaryButtonClick}
              >
                {secondaryButtonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ErrorDialog;
