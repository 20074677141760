import { useTranslation } from 'react-i18next';

import { ReactComponent as DEFlag } from '../assets/icons/de_flag.svg';
import { ReactComponent as ENFlag } from '../assets/icons/en_flag.svg';

export const SELECTED_LANGAUGE_STORAGE_KEY = '__docpad_selected_language__';
export const DE_X = 'de_de_x';
export const DE_M = 'de_de_m';
export const EN_GB = 'en_gb';

export const useLanguageOptions = () => {
  const { t } = useTranslation();
  return [
    {
      value: DE_X,
      label: t('dashboard.tabs.profile.languageSetting.german'),
      icon: <DEFlag />,
    },
    {
      value: EN_GB,
      label: t('dashboard.tabs.profile.languageSetting.english'),
      icon: <ENFlag />,
    },
  ];
};

export const useLanguageGenderOptions = () => {
  const { t } = useTranslation();
  return [
    {
      value: DE_X,
      label: t('dashboard.tabs.profile.languageSetting.germanBinary'),
    },
    {
      value: DE_M,
      label: t('dashboard.tabs.profile.languageSetting.germanMale'),
    },
  ];
};
