import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';

import CustomModal from '../../../components/modal/modal';
import Button from '../../../components/button/button';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Save } from '../../../assets/icons/save.svg';
import { InputText } from '../../../components/input';
import Dropdown from '../../../components/dropdown/dropdown';
import {
  getCareSettingFromPath,
  useFilteredGroups,
} from '../../../helpers/hooks/groups';
import {
  useAddGroup,
  useGroupById,
  useMoveGroup,
  useUpdateGroup,
} from '../../../localAdmin-api/queries/keycloakGroups';
import { useNavigate, useParams } from 'react-router-dom';

interface IAddEdit {
  mode: 'add' | 'edit';
  open: boolean;
  onClose: () => void;
  groupId?: string;
}

const AddEdit: React.FC<IAddEdit> = ({ mode, open, onClose }) => {
  const { t } = useTranslation();
  const filteredGroups = useFilteredGroups();
  const addGroup = useAddGroup();
  const updateGroup = useUpdateGroup();
  const moveGroup = useMoveGroup();
  const params = useParams();
  const { data: group } = useGroupById(params?.id || '');
  const navigate = useNavigate();
  const groupId = params?.id || '';

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isValid },
  } = useForm<{
    name: string;
    careSetting: string;
  }>();

  useEffect(() => {
    if (group) {
      reset({
        name: group.name || '',
        careSetting: getCareSettingFromPath(group.path) || '',
      });
    }
  }, [group, reset]);

  const careSettingOptions = useMemo(() => {
    return filteredGroups.map((filteredGroup) => {
      return {
        value: filteredGroup.name,
        label: t(`careSettings.${filteredGroup.name}`),
      };
    });
  }, [filteredGroups, t]);

  const getGroupId = (setting: string) => {
    return (
      filteredGroups.find((filteredGroup) => filteredGroup.name === setting)
        ?.id || ''
    );
  };

  const onSubmit = ({
    name,
    careSetting,
  }: {
    name: string;
    careSetting: string;
  }) => {
    if (mode === 'add') {
      // Handle add mode submission
      const parentId = getGroupId(careSetting);
      if (name && parentId) {
        addGroup.mutate(
          { name, parentId },
          {
            onSuccess: (groupData) => {
              // Add subgroups for supervisor and supervisee
              const { id } = groupData;
              addGroup.mutate(
                { parentId: id, name: 'supervisor' },
                {
                  onSuccess: () =>
                    addGroup.mutate(
                      { parentId: id, name: 'supervisee' },
                      {
                        onSuccess: () => {
                          handleClose();
                          toast.success(
                            <p id="successMessageAddDepartment">
                              {t(
                                'dashboard.tabs.departmentManagement.addDepartment.success',
                              )}
                            </p>,
                          );
                          navigate('/dashboard/department-management');
                        },
                      },
                    ),
                },
              );
            },
          },
        );
      }
    } else if (mode === 'edit' && groupId && group) {
      // Handle edit mode submission
      const parentId = getGroupId(careSetting);
      if (parentId) {
        updateGroup.mutate(
          {
            groupId,
            name,
            attributes: group.attributes,
            enabled: 'true',
          },
          {
            onSuccess: () => {
              moveGroup.mutate(
                {
                  parentId,
                  name,
                  groupId,
                },
                {
                  onSuccess: () => {
                    handleClose();
                    toast.success(
                      <p id="editDepartmentSuccessMessage">
                        {t(
                          'dashboard.tabs.departmentManagement.editDepartment.success',
                        )}
                      </p>,
                    );
                  },
                },
              );
            },
          },
        );
      }
    }
  };

  const handleClose = () => {
    onClose();
    reset({
      name: '',
      careSetting: '',
    });
    navigate('/dashboard/department-management');
  };

  return (
    <CustomModal isOpen={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="font-semibold titleL mb-spacing8">
          {t(`dashboard.tabs.departmentManagement.${mode}Department.headline`)}
        </p>
        <p className="bodyL mb-spacing6">
          {t(`dashboard.tabs.departmentManagement.${mode}Department.body`)}
        </p>
        <div className="w-2/3 mb-spacing8">
          <div className="mb-spacing8">
            <InputText
              {...register('name', { required: true })}
              label={t('general.departmentName')}
              placeholder={t(
                'dashboard.tabs.departmentManagement.addDepartment.departmentNamePlaceholder',
              )}
            />
          </div>
          <Controller
            name="careSetting"
            control={control}
            defaultValue={undefined}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                label={t('general.careSetting')}
                placeholder={t(
                  'dashboard.tabs.departmentManagement.addDepartment.careSettingPlaceholder',
                )}
                options={careSettingOptions}
                value={value}
                onChange={(e) => onChange(e.value)}
                id="dropdown-care-setting"
              />
            )}
          />
        </div>
        <div className="flex gap-spacing4">
          {mode === 'add' ? (
            <Button
              type="submit"
              icon={<Plus />}
              data-cy="button-create-department"
              disabled={!isValid}
            >
              {t('dashboard.tabs.departmentManagement.addDepartment.button')}
            </Button>
          ) : (
            <div className="flex gap-spacing4">
              <Button
                type="submit"
                icon={<Save />}
                data-cy="button-edit-department"
              >
                {t('buttons.save')}
              </Button>
              <Button
                type="button"
                onPress={handleClose}
                backgroundStyle={'tertiary'}
              >
                {t('buttons.cancel')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </CustomModal>
  );
};
export default AddEdit;
