import { useTranslation } from 'react-i18next';
import Logo from '../../components/logo/logo';
import Button from '../../components/button/button';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onNavigateToPasswordSetup = () => {
    navigate('/set-password');
  };

  return (
    <div className="m-auto text-center min-w-1240 p-0-16">
      <div className="justify-center w-full mb-spacing15 ">
        <Logo size={'large'} centered noSubline />
      </div>
      <p className="whitespace-pre-wrap mb-spacing13">{t('welcome.body')}</p>
      <div className="flex justify-center mb-spacing13">
        <div className="justify-center text-center w-392">
          <Button onPress={onNavigateToPasswordSetup}>
            {t('welcome.button')}
          </Button>
        </div>
      </div>
      <p>
        {t('welcome.contact')}
        <a href="mailto:global.admin@docpad.com">{t('welcome.email')}</a>
      </p>
      <p className="whitespace-pre-wrap mt-spacing11">
        {t('welcome.greetings')}
      </p>
    </div>
  );
};

export default Welcome;
