import { useMemo } from 'react';

import { useGetDepartments } from '../../localAdmin-api/queries/departments';

/*
 * Returns the filtered departments
 * @returns the list of departments
 */
export const useFilteredGroups = () => {
  const { data: groups } = useGetDepartments();

  // Subgroups are the departments within an institution
  const subgroups = groups?.flatMap((group) => group.subGroups);

  return useMemo(() => {
    return (subgroups || []).filter(
      (group) =>
        group.name === 'emergency_care' ||
        group.name === 'in_care' ||
        group.name === 'out_care',
    );
  }, [subgroups]);
};

/*
 * Extracts the care setting from the path
 * @param path - the path to extract the care setting from
 * @returns the care setting
 *
 * Example:
 * getCareSettingFromPath('/institution/out_care/Radiologie') => 'out_care'
 */
export const getCareSettingFromPath = (path: string) => {
  return path.split('/')[2];
};
