import { Link, useLocation } from 'react-router-dom';

import Tab from '../tab';
import { useSidebarLinks } from './constants';

const Sidebar = () => {
  const location = useLocation();
  const links = useSidebarLinks();

  return (
    <div className="flex flex-col bg-surface-appBackground py-spacing10 px-spacing9 min-w-[392px] h-[296px] rounded-spacing6 gap-y-10">
      {links.map((record) => (
        <Link to={record.pathname} key={record.pathname} state={record.state}>
          <Tab
            label={record.label}
            active={location.pathname === record.pathname}
            icon={record.icon}
            data-cy={record.dataCy}
          />
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
