import { useTranslation } from 'react-i18next';
import { ReactComponent as Trash } from '../../assets/icons/remove.svg';
import CustomModal from '../../components/modal/modal';
import Button from '../../components/button/button';
import { clsx } from 'clsx';
import { useUpdateUser, useUserById } from '../../localAdmin-api/queries/user';
import { displayString } from '../../helpers/users';
import { useMemo } from 'react';
import { getCareSettingFromPath } from '../../helpers/hooks/groups';
import {
  useGroupById,
  useUpdateGroup,
} from '../../localAdmin-api/queries/keycloakGroups';
import { toast } from 'react-hot-toast';

interface IDeactivation {
  mode: 'user' | 'department';
  open: boolean;
  onClose: () => void;
  userId?: string;
  groupId?: string;
}

// TODO: Create separate modal for users and departments
const Deactivation = ({
  mode,
  open,
  onClose,
  userId,
  groupId,
}: IDeactivation) => {
  const { t } = useTranslation();
  const { data: user } = useUserById(userId || '');
  const { data: group } = useGroupById(groupId || '');
  const updateGroup = useUpdateGroup();
  const updateUser = useUpdateUser(userId || '');

  // Set form data with current user values
  const payload = useMemo(() => {
    if (user) {
      return {
        ...user,
        attributes: {
          ...user.attributes,
        },
        enabled: false,
      };
    }
  }, [user]);

  const onDeactivateUser = () => {
    if (payload) {
      updateUser.mutate(payload, {
        onSuccess: () => {
          onClose();
          toast.success(
            t('dashboard.tabs.userManagement.deactivation.success'),
          );
        },
      });
    }
  };

  const onDeactivateGroup = () => {
    if (groupId && group)
      updateGroup.mutate(
        {
          groupId: groupId,
          name: group.name,
          attributes: group.attributes,
          enabled: 'false',
        },
        {
          onSuccess: () => {
            onClose();
            toast.success(
              <p id="deactivationMessage">
                {t('dashboard.tabs.departmentManagement.deactivation.success')}
              </p>,
            );
          },
        },
      );
  };

  return (
    <CustomModal isOpen={open} onClose={onClose}>
      <p className="font-semibold titleL mb-spacing8">
        {t(`dashboard.tabs.${mode}Management.deactivation.headline`)}
      </p>
      <p className="bodyL mb-spacing6">
        {t(`dashboard.tabs.${mode}Management.deactivation.body`)}
      </p>
      <p className="bodyL text-text-secondary mb-spacing8">
        {t(`dashboard.tabs.${mode}Management.deactivation.disclaimer`)}
      </p>
      <div className="greyBox mb-spacing8">
        <div className="flex gap-spacing4">
          <p className="bodyL mb-spacing6">
            {mode === 'user'
              ? `${t('general.firstName')}:`
              : `${t('general.departmentName')}:`}
          </p>
          <p className="font-semibold bodyL mb-spacing6">
            {mode === 'user'
              ? `${displayString(user?.firstName)}`
              : `${group?.name}`}
          </p>
        </div>
        <div className="flex gap-spacing4">
          <p className={clsx('bodyL ', { 'mb-spacing6': mode === 'user' })}>
            {mode === 'user'
              ? `${t('general.lastName')}:`
              : `${t('general.careSetting')}:`}
          </p>
          <p
            className={clsx('bodyL font-semibold', {
              'mb-spacing6': mode === 'user',
            })}
          >
            {mode === 'user'
              ? `${displayString(user?.lastName)}`
              : `${group && t(`careSettings.${getCareSettingFromPath(group.path)}`)}`}
          </p>
        </div>
        {mode === 'user' && (
          <div className="flex gap-spacing4">
            <p className="bodyL">{t('general.LANR')}:</p>
            <p className="font-semibold bodyL">
              {displayString(user?.attributes?.lanr)}
            </p>
          </div>
        )}
      </div>
      <div className="flex gap-spacing4">
        <Button
          onPress={mode === 'user' ? onDeactivateUser : onDeactivateGroup}
          icon={<Trash />}
          data-cy="button-deactivate"
        >
          {t('buttons.deactivate')}
        </Button>
        <Button backgroundStyle={'tertiary'} onPress={onClose}>
          {t('buttons.cancel')}
        </Button>
      </div>
    </CustomModal>
  );
};
export default Deactivation;
