import Logo from '../logo/logo';

const RightContainer = () => {
  return (
    <div className="flex justify-center bg-surface-appBackground">
      <div data-cy="logo" className="flex flex-col self-center max-w-392">
        <Logo size={'large'} />
      </div>
    </div>
  );
};

export default RightContainer;
