import { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

interface IProgressBar {
  duration: number;
}

const ProgressBar = ({ duration }: IProgressBar) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    setTimeout(
      () => {
        if (progress > 0) {
          setProgress(progress - 1);
        }
      },
      // to see the whole animation before toast disappears
      (duration - 1000) / 100,
    );

    // reset animation after toast disappeared
    setTimeout(() => setProgress(0), duration + 100);
  }, [progress]);

  return (
    <div className="w-spacing9 h-spacing9">
      <CircularProgressbar
        value={progress}
        strokeWidth={5}
        styles={buildStyles({
          pathColor: '#FFFFFF',
          trailColor: 'transparent',
        })}
      />
    </div>
  );
};
export default ProgressBar;
