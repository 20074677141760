import { useKeycloak } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';
import { RouterProvider } from 'react-router-dom';

import NotificationToast from './components/toast/notificationToast';
import { ErrorProvider } from './contextProviders/generalErrorContext';
import { eventSubscriber, eventUnsubscriber } from './helpers/customEvents';
import './index.css';
import apiClient from './localAdmin-api/apiClient';
import { router } from './router';
import './translation';

ReactModal.setAppElement('#root');

const queryClient = new QueryClient();

const App = () => {
  const { keycloak } = useKeycloak();

  const handleLogout = useCallback(async () => {
    try {
      await keycloak.logout({
        redirectUri: `${window.location.origin}/auto-logout`,
      });
    } catch (error) {
      console.error('Logout failed', error);
    }
  }, []);

  useEffect(() => {
    if (keycloak.token) {
      apiClient.setToken(keycloak.token);
    }
  }, [keycloak.token]);

  useEffect(() => {
    eventSubscriber('logout', handleLogout);

    return () => {
      eventUnsubscriber('logout', handleLogout);
    };
  }, [handleLogout]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="grid h-screen m-0-auto p-0-spacing2">
        <ErrorProvider>
          <RouterProvider router={router(keycloak)} />
        </ErrorProvider>
      </div>
      <NotificationToast />
    </QueryClientProvider>
  );
};

export default App;
