import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as Save } from '../../../assets/icons/save.svg';
import Button from '../../../components/button/button';
import Details from './details';
import { UserModalType } from '.';
import { useAddUser } from '../../../localAdmin-api/queries/keycloakUser';
import { useUpdateUser } from '../../../localAdmin-api/queries/user';
import { useUserById } from '../../../localAdmin-api/queries/user';
import { UserRepresentation } from '../../../localAdmin-api/open-api';

interface IUserCreation {
  mode: UserModalType;
  onClose: () => void;
  userId?: string;
}

const ModalScreen: React.FC<IUserCreation> = ({
  mode,
  onClose,
  userId = '',
}) => {
  const { t } = useTranslation();
  const addUser = useAddUser();
  const updateUser = useUpdateUser(userId);
  const { data: userData } = useUserById(userId);
  const methods = useForm<UserRepresentation>();
  const {
    formState: { isValid },
    reset,
  } = methods;

  React.useEffect(() => {
    if (userData && mode !== 'details') {
      reset(userData);
    }
  }, [userData, reset, mode]);

  const onSubmit = (data: UserRepresentation) => {
    if (mode === 'edit') {
      updateUser.mutate(data, {
        onSuccess: () => {
          onClose();
          toast.success(t('dashboard.tabs.userManagement.editUser.success'));
        },
      });
    } else {
      const dataKeycloakFormat = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        enabled: data.enabled ? data.enabled : undefined,
        groups: data.groups,
        attributes: data.attributes,
      };

      addUser.mutate(dataKeycloakFormat, {
        onSuccess: () => {
          onClose();
          toast.success(t('dashboard.tabs.userManagement.addUser.success'));
        },
      });
    }
  };

  return (
    <div className="absolute top-0 left-0 w-full bg-surface-primary px-spacing7 py-spacing10">
      <div className="flex justify-between">
        <p className="titleL mb-spacing8">
          {mode === 'edit'
            ? t('dashboard.tabs.userManagement.editUser.headline')
            : mode === 'add'
              ? t('dashboard.tabs.userManagement.addUser.headline')
              : t('dashboard.tabs.userManagement.userDetails.headline')}
        </p>
        <div className="top-0">
          <button onClick={onClose}>
            <Close />
          </button>
        </div>
      </div>
      <p className="bodyL mb-spacing8">
        {mode === 'edit'
          ? t('dashboard.tabs.userManagement.editUser.body')
          : mode === 'add'
            ? t('dashboard.tabs.userManagement.addUser.body')
            : t('dashboard.tabs.userManagement.userDetails.body')}
      </p>
      {mode === 'details' && userId ? (
        <Details userId={userId} />
      ) : (
        <FormProvider {...methods}>
          <Outlet />
        </FormProvider>
      )}
      {mode === 'edit' ? (
        <div className="flex">
          <Button
            disabled={!isValid}
            onPress={methods.handleSubmit(onSubmit)}
            icon={<Save />}
            data-cy="user-save"
          >
            {t('buttons.save')}
          </Button>
          <Button onPress={onClose} backgroundStyle={'tertiary'}>
            {t('buttons.cancel')}
          </Button>
        </div>
      ) : mode === 'add' ? (
        <Button
          disabled={!isValid}
          onPress={methods.handleSubmit(onSubmit)}
          icon={<Plus />}
          data-cy="user-add"
        >
          {t('dashboard.tabs.userManagement.addUser.button')}
        </Button>
      ) : (
        <Button onPress={onClose}>{t('buttons.back')}</Button>
      )}
    </div>
  );
};

export default ModalScreen;
