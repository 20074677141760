import { ReactNode } from 'react';

import Header from '../header';

const PrivateLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default PrivateLayout;
