import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { InputText, DateInput } from '../../../components/input';
import Dropdown from '../../../components/dropdown/dropdown';
import {
  useDepartmentOptions,
  useGenderOptions,
  useUserRoleOptions,
} from '../../../helpers/dropdownOptions';
import MultiDropdown from '../../../components/dropdown/multiDropdown';
import { useMedicalSpecialistList } from '../../../localAdmin-api/queries/medicalSpecialistList';
import { UserRepresentation } from '../../../localAdmin-api/open-api';
import { getDepartments, getUserRole } from '../../../helpers/users';
import { useCallback, useState } from 'react';

const AddEdit = () => {
  const { t } = useTranslation();
  const genderOptions = useGenderOptions();
  const departmentOptions = useDepartmentOptions();
  const roleOptions = useUserRoleOptions();
  const { data: specialtyOptions } = useMedicalSpecialistList();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const { register, control, watch } = useFormContext<UserRepresentation>();

  const onMailInputBlur = () => {
    const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    const value = watch('email');
    if (value) {
      reg.test(value) ? setIsEmailValid(true) : setIsEmailValid(false);
    }
  };

  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18),
  );
  const minDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 100),
  );

  const dateInputValidate = {
    required: (value: string[] | null | undefined) =>
      (dayjs(value?.[0], 'DD-MM-YYYY').isValid() &&
        new Date(dayjs(value?.[0], 'DD-MM-YYYY').toDate()).getFullYear() >
          new Date().getFullYear() - 100 &&
        new Date(dayjs(value?.[0], 'DD-MM-YYYY').toDate()).getFullYear() <
          new Date().getFullYear() - 18) ||
      t('general.fieldIsMandatory'),
  };

  const renderDateInput = useCallback(
    ({
      field: { value, onChange },
    }: {
      field: { value: any; onChange: any };
    }) => {
      const isDateValid =
        (new Date(
          dayjs(watch('attributes.birthdate')?.[0], 'DD-MM-YYYY').toDate(),
        ).getFullYear() >
          new Date().getFullYear() - 100 &&
          new Date(
            dayjs(watch('attributes.birthdate')?.[0], 'DD-MM-YYYY').toDate(),
          ).getFullYear() <
            new Date().getFullYear() - 18) ||
        watch('attributes.birthdate')?.[0] === undefined;
      return (
        <DateInput
          label={t('general.birthdate')}
          useRange={false}
          asSingle={true}
          placeholder={t(
            'dashboard.tabs.userManagement.addUser.personalInformation.birthdatePlaceholder',
          )}
          displayFormat={'DD-MM-YYYY'}
          maxDate={maxDate}
          minDate={minDate}
          value={dayjs(value?.[0], 'DD-MM-YYYY').toDate()}
          onChange={(v) => {
            onChange([dayjs(v).format('DD-MM-YYYY')]);
          }}
          showShortcuts={false}
          primaryColor="blue"
          toggleClassName="absolute right-0 h-full px-3 text-white"
          messageType={isDateValid ? undefined : 'error'}
          message={isDateValid ? undefined : t('general.wrongBirthdate')}
        />
      );
    },
    [],
  );

  return (
    <div className="flex gap-spacing9 mb-spacing8">
      <div className="flex-1 greyBox">
        <p className="bodyL mb-spacing6">
          {t(
            'dashboard.tabs.userManagement.addUser.personalInformation.headline',
          )}
        </p>
        <div className="flex flex-col gap-spacing6">
          <InputText
            label={t('general.firstName')}
            placeholder={t(
              'dashboard.tabs.userManagement.addUser.personalInformation.firstNamePlaceholder',
            )}
            {...register('firstName', { required: true })}
          />

          <InputText
            label={t('general.lastName')}
            placeholder={t(
              'dashboard.tabs.userManagement.addUser.personalInformation.lastNamePlaceholder',
            )}
            {...register('lastName', { required: true })}
          />

          <Controller
            control={control}
            name="attributes.title"
            render={({ field: { value, onChange } }) => {
              return (
                <InputText
                  label={`${t('general.title')} (${t('general.optional')})`}
                  placeholder={t(
                    'dashboard.tabs.userManagement.addUser.personalInformation.titlePlaceholder',
                  )}
                  value={value?.[0]}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                />
              );
            }}
          />

          <Controller
            name="attributes.gender"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Dropdown
                label={`${t('general.gender')} (${t('general.optional')})`}
                options={genderOptions}
                onChange={(e) => {
                  onChange([e.value]);
                }}
                value={value?.[0]}
              />
            )}
          />

          <Controller
            control={control}
            name="attributes.birthdate"
            defaultValue={undefined}
            rules={{
              required: true,
              validate: dateInputValidate,
            }}
            render={({ field: { value, onChange } }) =>
              renderDateInput({ field: { value, onChange } })
            }
          />

          <Controller
            control={control}
            name="attributes.birthplace"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <InputText
                  label={t('general.birthplace')}
                  placeholder={t(
                    'dashboard.tabs.userManagement.addUser.personalInformation.birthplacePlaceholder',
                  )}
                  value={value?.[0]}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                  data-cy="input-birthplace"
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex-1 greyBox">
        <p className="bodyL mb-spacing6">
          {t(
            'dashboard.tabs.userManagement.addUser.professionalInformation.headline',
          )}
        </p>
        <div className="flex flex-col gap-spacing6">
          <InputText
            label={t('general.email')}
            placeholder={t(
              'dashboard.tabs.userManagement.addUser.professionalInformation.emailPlaceholder',
            )}
            {...register('email', { required: true })}
            onBlur={() => onMailInputBlur()}
            messageType={!isEmailValid ? 'error' : undefined}
            message={!isEmailValid ? t('general.wrongEmail') : ''}
          />

          <Controller
            control={control}
            name="attributes.approbationNumber"
            render={({ field: { value, onChange } }) => {
              return (
                <InputText
                  label={`${t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.licenseNumber',
                  )} (${t('general.optional')})`}
                  placeholder={t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.licenseNumberPlaceholder',
                  )}
                  value={value?.[0]}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                  data-cy="input-license-number"
                />
              );
            }}
          />

          <Controller
            control={control}
            name="attributes.lanr"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <InputText
                  label={t('general.LANR')}
                  placeholder={t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.LANRPlaceholder',
                  )}
                  value={value?.[0]}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                  data-cy="input-lanr"
                />
              );
            }}
          />

          {specialtyOptions && (
            <Controller
              name="attributes.facharztStatus"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MultiDropdown
                  label={t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.speciality',
                  )}
                  options={specialtyOptions}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value ? value : undefined}
                  isOptional
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="attributes.phone"
            render={({ field: { value, onChange } }) => {
              return (
                <InputText
                  label={`${t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.telephone',
                  )} (${t('general.optional')})`}
                  placeholder={t(
                    'dashboard.tabs.userManagement.addUser.professionalInformation.telephonePlaceholder',
                  )}
                  value={value?.[0]}
                  onChange={(e) => {
                    onChange([e.target.value]);
                  }}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="flex-1 greyBox">
        <p className="bodyL mb-spacing6">
          {t('dashboard.tabs.userManagement.addUser.details.headline')}
        </p>
        <div className="flex flex-col gap-spacing6">
          {departmentOptions && (
            <Controller
              name="groups"
              control={control}
              defaultValue={[]}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <MultiDropdown
                    label={t(
                      'dashboard.tabs.userManagement.addUser.details.department',
                    )}
                    options={departmentOptions}
                    onChange={(e) => {
                      if (typeof e === 'string') {
                        e = [e];
                      }
                      const role = getUserRole(value);
                      onChange(
                        getDepartments(e).map((group) =>
                          role ? `${group}/${role}` : group,
                        ),
                      );
                    }}
                    value={getDepartments(value)}
                    isDepartment
                    id="dropdown-department"
                  />
                );
              }}
            />
          )}

          <Controller
            name="groups"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <div id="dropdown-user-role">
                  <Dropdown
                    label={t(
                      'dashboard.tabs.userManagement.addUser.details.userRole',
                    )}
                    options={roleOptions}
                    onChange={(e) => {
                      // Update each group value with /<role>
                      onChange(
                        getDepartments(value)?.map(
                          (group) => `${group}/${e.value}`,
                        ),
                      );
                    }}
                    value={getUserRole(value)}
                  />
                </div>
              );
            }}
          />

          <div className="flex gap-spacing2">
            <Info />
            <p className="bodyS">
              {t('dashboard.tabs.userManagement.addUser.details.info')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddEdit;
