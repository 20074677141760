import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as SignOut } from '../../assets/icons/signOut.svg';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';

const Logout = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await keycloak.logout();
      console.log('Logout successful');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  return (
    <div className="relative flex items-center group">
      <p className="m-0 bodyM text-text-primary">
        {keycloak.idTokenParsed?.email}
      </p>
      <button className="ml-spacing4 w-[44px] h-[44px] rounded-full bg-button-primaryDefault group-hover:bg-button-secondaryDefault">
        <div className="p-spacing5 text-icon-invert group-hover:text-text-brandPrimary">
          <ProfileIcon />
        </div>
      </button>
      <div className="absolute invisible w-full top-spacing10 group-hover:visible">
        <button
          className="w-full border mt-spacing4 customShadow bg-surface-primary p-spacing4 rounded-spacing2 border-border-grayInputDefault"
          onClick={() => setIsModalOpen(true)}
          id="logout"
        >
          <div className="flex items-center bg-surface-primary py-spacing5 px-spacing2">
            <SignOut />
            <p className="font-semibold titleXS pl-spacing4">
              {t('logout.button')}
            </p>
          </div>
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div>
          <p className="font-semibold titleL mb-spacing8">
            {t('logout.modalHeadline')}
          </p>
          <p className="bodyL mb-spacing7">{t('logout.modalInfo')}</p>
          <div className="flex gap-spacing6">
            <Button onPress={handleLogout} icon={<SignOut />} id="logout-modal">
              {t('logout.button')}
            </Button>

            <Button
              onPress={() => setIsModalOpen(false)}
              backgroundStyle={'tertiary'}
            >
              {t('buttons.cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Logout;
