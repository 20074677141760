import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';

import Button from '../../../components/button/button';
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg';
import LanguageSelectCard from './language-selector';
import { useUserById } from '../../../localAdmin-api/queries/user';
import { displayString } from '../../../helpers/users';
import { useGetUserProfile } from '../../../helpers/hooks/useUser';

const Settings = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { profile, userId, selectedLanguage, onUpdateUser } =
    useGetUserProfile();
  const { data: userData } = useUserById(userId || '');

  const onLanguageChange = (value: string) => {
    if (userData) {
      onUpdateUser({
        ...userData,
        attributes: {
          ...userData.attributes,
          languageSelection: [value],
        },
      });
    }
  };

  const onNavigateToChangePassword = () => {
    if (keycloak) {
      // direct user to keycloak change password settings
      const server = keycloak.authServerUrl;
      const realm = keycloak.realm;
      const clientId = keycloak.clientId;
      const redirectUri = window.location.href;

      window.location.href =
        `${server}realms/${realm}/protocol/openid-connect/auth?client_id=${clientId}` +
        `&redirect_uri=${redirectUri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;
    }
  };

  return (
    <div className="flex gap-spacing9">
      <div>
        <div className="greyBox w-[392px] mb-spacing8">
          <p className="titleM mb-spacing8">
            {t('dashboard.tabs.profile.profileInformation')}
          </p>
          <div className="flex">
            <p className="bodyL mb-spacing6 mr-spacing4">
              {t('general.firstName')}:
            </p>
            <p className="titleS">{displayString(profile?.firstName)}</p>
          </div>
          <div className="flex">
            <p className="bodyL mb-spacing6 mr-spacing4">
              {t('general.lastName')}:
            </p>
            <p className="titleS">{displayString(profile?.lastName)}</p>
          </div>
          <div className="flex">
            <p className="bodyL mb-spacing6 mr-spacing4">
              {t('general.birthdate')}:
            </p>
            <p className="titleS">
              {displayString(profile?.attributes?.birthdate)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4">{t('general.email')}:</p>
            <p className="titleS">{displayString(profile?.email)}</p>
          </div>
        </div>
        <LanguageSelectCard
          value={selectedLanguage}
          onChange={onLanguageChange}
        />
      </div>
      <div className="greyBox h-full w-[392px]">
        <div className="w-1/2">
          <Button
            onPress={onNavigateToChangePassword}
            icon={<PenIcon />}
            data-cy="button-change-password"
          >
            {t('dashboard.tabs.profile.changePassword')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
