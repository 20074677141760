import { useTranslation } from 'react-i18next';
import { includes, startsWith } from 'lodash';

import {
  useLanguageOptions,
  useLanguageGenderOptions,
  DE_X,
  DE_M,
} from '../../../constants/languageOptions';
import RadioButtonGroup from '../../../components/radioButtonGroup/radioButtonGroup';

interface ILanguageSelectCard {
  value?: string;
  onChange: (value: string) => void;
}

const LanguageSelectCard = ({ value = '', onChange }: ILanguageSelectCard) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();
  const languageGenderOptions = useLanguageGenderOptions();

  return (
    <div className="greyBox w-[392px]">
      <p className="titleM mb-spacing8">
        {t('dashboard.tabs.profile.languageSetting.headline')}
      </p>
      <p className="bodyS text-text-primary mb-spacing6">
        {t('dashboard.tabs.profile.languageSetting.selectedLanguage')}
      </p>
      <div className="mb-spacing8">
        <RadioButtonGroup
          options={languageOptions}
          onChange={(v) => onChange(v as string)}
          value={includes([DE_X, DE_M], value) ? DE_X : value}
          rows
        />
      </div>
      <p className="bodyS text-text-primary mb-spacing6">
        {t('dashboard.tabs.profile.languageSetting.languageGender')}
      </p>
      <RadioButtonGroup
        disabled={!startsWith(value, 'de')}
        options={languageGenderOptions}
        value={value}
        onChange={(v) => onChange(v as string)}
        rows
      />
    </div>
  );
};

export default LanguageSelectCard;
