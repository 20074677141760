import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface ITooltip {
  id: string;
}

const Tooltip = ({ id }: ITooltip) => {
  const { t } = useTranslation();

  return (
    <ReactTooltip
      id={id}
      place="top-start"
      style={{ backgroundColor: '#F4F3F7' }}
    >
      <div className="w-[415px] p-spacing3 bg-surface-visualSeparator rounded-spacing2">
        <p className="titleXXS font-semibold uppercase mb-spacing5 text-text-primary">
          {t('dashboard.tabs.departmentManagement.editDisclaimer.headline')}
        </p>
        <p className="bodyS text-text-primary">
          {t('dashboard.tabs.departmentManagement.editDisclaimer.body')}
        </p>
      </div>
    </ReactTooltip>
  );
};

export default Tooltip;
