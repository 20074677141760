import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useGetDepartments } from '../localAdmin-api/queries/departments';

export const useGenderOptions = () => {
  const { t } = useTranslation();
  return [
    { value: 'divers', label: t('general.genderOptions.divers') },
    { value: 'female', label: t('general.genderOptions.female') },
    {
      value: 'male',
      label: t('general.genderOptions.male'),
    },
  ];
};

export const useDepartmentOptions = () => {
  const { data: groups } = useGetDepartments();

  return useMemo(() => {
    let options, filteredGroups, departments;
    const subgroups = groups?.flatMap((group) => group.subGroups);

    if (subgroups) {
      filteredGroups = subgroups.filter(
        (group) =>
          group.name === 'emergency_care' ||
          group.name === 'out_care' ||
          group.name === 'in_care',
      );
      departments = filteredGroups.flatMap((group) => group.subGroups);
      options = departments.map((group: any) => {
        if (group.attributes.enabled?.[0] !== 'false') {
          return {
            value: group?.path,
            label: group?.name,
          };
        }
      });
    }

    return options?.filter((obj) => obj !== undefined) as {
      value: string;
      label: string;
    }[];
  }, [groups]);
};

export const useUserRoleOptions = () => {
  const { t } = useTranslation();
  return [
    { value: 'supervisor', label: t('general.supervisor') },
    { value: 'supervisee', label: t('general.supervisee') },
  ];
};
