import { useTranslation } from 'react-i18next';
import Logo from '../logo/logo';
import Logout from '../../screens/dashboard/logout';

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-surface-appBackground py-spacing7 px-spacing11 mb-spacing10">
      <Logo size={'medium'} />
      <div className="flex items-center justify-between w-full">
        <div className="ml-spacing10 p-spacing5 bg-surface-primary rounded-spacing8">
          <p className="m-0 bodyM text-text-primary">
            {t('dashboard.header.institution')}: institution name
          </p>
        </div>
        <Logout />
      </div>
    </div>
  );
};

export default Header;
