import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import RightContainer from '../../components/login/rightContainer';
import Button from '../../components/button/button';
import { InputText } from '../../components/input';

const Login = () => {
  const { keycloak } = useKeycloak();
  const [loginVisible, setLoginVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswortVisible] = useState(false);
  const [checkEmailVisible, setCheckEmailVisible] = useState(false);
  const [passwordSetupVisible, setPasswortSetupVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onLogin = () => {
    setLoginVisible(true);
    setResetPasswortVisible(false);
    setCheckEmailVisible(false);
    setPasswortSetupVisible(false);
    navigate('/dashboard');
  };
  const onForgotPassword = () => {
    setLoginVisible(false);
    setResetPasswortVisible(true);
    setCheckEmailVisible(false);
    setPasswortSetupVisible(false);
  };
  const onResetPassword = () => {
    setLoginVisible(false);
    setResetPasswortVisible(false);
    setCheckEmailVisible(true);
    setPasswortSetupVisible(false);
  };
  const onBackToLogin = () => {
    keycloak?.login();
  };

  const onChangePassword = () => {
    setLoginVisible(false);
    setResetPasswortVisible(false);
    setCheckEmailVisible(false);
    setPasswortSetupVisible(false);
  };

  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-0 min-w-1280 p-0-16 m-0-auto">
      <div className="flex justify-center">
        <div className="self-center flex flex-col w-[392px]">
          {loginVisible && (
            <div>
              <p className="titleXXL mb-spacing10">{t('login.headline')}</p>
              <form className="flex flex-col gap-10">
                <InputText
                  label="E-Mail"
                  type="email"
                  name={t('login.emailInput')}
                  placeholder={t('login.emailPlaceholder')}
                />
                <div>
                  <InputText
                    label="Password"
                    type="password"
                    name={t('login.passwordInput')}
                    placeholder={t('login.passwordPlaceholder')}
                  />
                  <Button
                    onPress={onForgotPassword}
                    backgroundStyle={'tertiary'}
                  >
                    {t('login.forgotPassword')}
                  </Button>
                </div>
                <div>
                  <Button onPress={onLogin} fullWidth>
                    {t('login.button')}
                  </Button>
                </div>
              </form>
            </div>
          )}
          {resetPasswordVisible && (
            <>
              <p className="titleXXL mb-spacing10">
                {t('resetPassword.headline')}
              </p>
              <p className="whitespace-pre-wrap bodyM mb-spacing10 text-text-primary">
                {t('resetPassword.body')}
              </p>
              <form>
                <div className="mb-spacing10">
                  <InputText
                    label="E-Mail"
                    type="email"
                    name={t('resetPassword.emailInput')}
                    placeholder={t('resetPassword.emailPlaceholder')}
                  />
                </div>

                <Button onPress={onResetPassword} fullWidth>
                  {t('resetPassword.resetButton')}
                </Button>

                <Button
                  onPress={onLogin}
                  backgroundStyle={'tertiary'}
                  centered
                  fullWidth
                >
                  {t('resetPassword.loginButton')}
                </Button>
              </form>
            </>
          )}
          {checkEmailVisible && (
            <>
              <div className="mb-spacing10">
                <p className="titleXXL mb-spacing10">
                  {t('checkEmail.headline')}
                </p>
                <p className="whitespace-pre-wrap bodyM text-text-primary">
                  {t('checkEmail.body')}
                </p>
                <ul>
                  <li className="whitespace-pre-wrap bodyM text-text-primary">
                    {t('checkEmail.li1')}
                  </li>
                  <li className="whitespace-pre-wrap bodyM text-text-primary">
                    {t('checkEmail.li2')}
                  </li>
                </ul>
              </div>
              <Button onPress={onBackToLogin} backgroundStyle={'secondary'}>
                {t('checkEmail.button')}
              </Button>
            </>
          )}

          {passwordSetupVisible && (
            <>
              <p className="titleXXL mb-spacing10">
                {t('newPassword.headline')}
              </p>
              <form>
                <p>E-Mail</p>
                <input
                  name={t('newPassword.passwordInput')}
                  placeholder={t('newPassword.passwordPlaceholder')}
                />
                <p>E-Mail</p>
                <input
                  name={t('newPassword.repeatInput')}
                  placeholder={t('newPassword.repeatPlaceholder')}
                />
                <br />
                <button onClick={onChangePassword}>
                  {t('newPassword.button')}
                </button>
              </form>
            </>
          )}

          {!loginVisible && !resetPasswordVisible && !checkEmailVisible && (
            <>
              <p className="titleXXL mb-spacing10">{t('start.headline')}</p>
              <p className="whitespace-pre-wrap bodyM mb-spacing10 text-text-primary">
                {t('start.body')}
              </p>
              <Button onPress={onBackToLogin} data-cy="button-goto-login">
                {t('start.button')}
              </Button>
              <p className="bodyXS text-neutral-800 mt-spacing6">
                {t('start.disclaimer')}
              </p>
            </>
          )}
        </div>
      </div>
      <RightContainer />
    </div>
  );
};

export default Login;
