import { useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';
import { useMutation } from '@tanstack/react-query';

export const useGroupById = (groupId: string) => {
  return useQuery({
    enabled: !!groupId && groupId !== '',
    queryKey: ['groupById', groupId],
    queryFn: async (): Promise<Group> => {
      const { data } = await apiClient
        .getAxiosInstance()
        .get(
          apiClient.getKeycloakUrl(`groups/${groupId}`),
          apiClient.getAxiosConfig(),
        );
      return data;
    },
  });
};

export const useAddGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: { parentId: string; name: string }) => {
      const { data } = await apiClient
        .getAxiosInstance()
        .post(
          apiClient.getKeycloakUrl(`groups/${payload.parentId}/children`),
          { name: payload.name, attributes: { enabled: ['true'] } },
          apiClient.getAxiosConfig(),
        );
      return data;
    },
    onError: (err) => console.log(err),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['departments'] });
    },
  });
};

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: {
      groupId: string;
      name: string;
      attributes?: { [key: string]: string };
      enabled?: string;
    }) => {
      const { data } = await apiClient.getAxiosInstance().put(
        apiClient.getKeycloakUrl(`groups/${payload.groupId}`),
        {
          name: payload.name,
          attributes: {
            ...payload.attributes,
            enabled: [payload.enabled ? payload.enabled : 'true'],
          },
        },
        apiClient.getAxiosConfig(),
      );
      return data;
    },
    onError: (err) => console.log(err),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['departments'] });
    },
  });
};

export const useMoveGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: {
      groupId: string;
      parentId: string;
      name: string;
    }) => {
      const { data } = await apiClient
        .getAxiosInstance()
        .post(
          apiClient.getKeycloakUrl(`groups/${payload.parentId}/children`),
          { id: payload.groupId, name: payload.name },
          apiClient.getAxiosConfig(),
        );
      return data;
    },
    onError: (err) => console.log(err),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['departments'] });
    },
  });
};
