import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoSmallIcon } from '../../assets/icons/logoSmall.svg';

interface ILogo {
  size: 'medium' | 'large';
  centered?: boolean;
  noSubline?: boolean;
}

const Logo = ({ size, centered, noSubline }: ILogo) => {
  const { t } = useTranslation();
  return (
    <div className="block">
      <div className={clsx('flex w-full', { 'justify-center': centered })}>
        {size === 'medium' ? <LogoSmallIcon /> : <LogoIcon />}
      </div>
      {!noSubline && (
        <p
          className={clsx('mt-spacing2', {
            titleXXS: size === 'medium',
            titleL: size !== 'medium',
          })}
        >
          {t('localAdmin')}
        </p>
      )}
    </div>
  );
};

export default Logo;
