import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

interface IFilterButton {
  onPress: () => void;
  active: boolean;
}

const FilterButton: React.FC<IFilterButton> = ({ onPress, active }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onPress}
      className={clsx(
        'flex align-center h-[36px] p-spacing4 gap-spacing2 rounded-spacing2 cursor-pointer',
        { 'bg-surface-brandSecondary': active },
      )}
      data-cy="toggle-deactivated-users"
    >
      <span className="bodyM text-text-brandPrimary">
        {t(
          `dashboard.tabs.userManagement.filterButton${active ? 'Hide' : 'Show'}`,
        )}
      </span>
      {active && (
        <div className="text-text-brandPrimary">
          <CloseIcon />
        </div>
      )}
    </button>
  );
};

export default FilterButton;
