import { useTranslation } from 'react-i18next';

const PatientRemoval = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="greyBox w-[816px]">
        <p className="font-semibold titleM mb-spacing5">
          {t('dashboard.tabs.patientRemoval.subline')}
        </p>
        <p className="bodyL">{t('dashboard.tabs.patientRemoval.body')}</p>
        <ul>
          <li className="bodyL">
            <span>{`${t('dashboard.tabs.patientRemoval.li1')} `}</span>
            <span>
              <a href={`mailto:${t('dashboard.tabs.patientRemoval.email')}`}>
                {t('dashboard.tabs.patientRemoval.email')}
              </a>
            </span>
          </li>
          <li className="bodyL">{t('dashboard.tabs.patientRemoval.li2')}</li>
        </ul>
      </div>
    </>
  );
};

export default PatientRemoval;
