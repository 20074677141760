import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

interface ICustomModal {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomModal: React.FC<ICustomModal> = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[816px] bg-surface-primary p-spacing10 rounded-spacing6 customShadow"
    >
      <button className="absolute right-spacing10" onClick={onClose}>
        <Close />
      </button>
      {children}
    </Modal>
  );
};

export default CustomModal;
