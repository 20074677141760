import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { PrivateLayout } from '../layout';

const PrivateGuard = ({
  children,
  isAuthenticated,
}: {
  children: ReactNode;
  isAuthenticated?: boolean;
}) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <PrivateLayout>{children}</PrivateLayout>;
};

export default PrivateGuard;
