export default {
  translation: {
    appName: 'DocPad',
    localAdmin: 'Local-Admin',
    general: {
      optional: 'optional',
      firstName: 'Vorname',
      lastName: 'Nachname',
      birthdate: 'Geburtsdatum',
      birthplace: 'Geburtsort',
      email: 'E-Mail-Adresse',
      password: 'Passwort',
      LANR: 'LANR',
      department: 'Abteilung',
      userRole: 'Rolle des Benutzers',
      title: 'Titel',
      gender: 'Geschlecht',
      departmentName: 'Name der Abteilung',
      careSetting: 'Versorgungstyp',
      supervisor: 'Supervisor',
      supervisee: 'Assistenz',
      genderOptions: { divers: 'divers', female: 'weiblich', male: 'männlich' },
      selectPlaceholder: 'Bitte auswählen',
      selectedPlaceholder: 'ausgewählt',
      deactivated: 'deaktiviert',
      fieldIsMandatory:
        'Dieses Feld muss ausgefüllt werden, bevor Sie fortfahren können.',
      wrongEmail: 'Die eingegebene Email Adresse ist ungültig.',
      wrongBirthdate: 'Das Alter muss zwischen 18 und 100 Jahren liegen.',
    },
    careSettings: {
      emergency_care: 'Notfall',
      in_care: 'Stationär',
      out_care: 'Ambulant',
    },
    buttons: {
      save: 'Speichern',
      cancel: 'Abbrechen',
      back: 'Zurück',
      deactivate: 'Deaktivieren',
      reportError: 'Diesen Fehler melden',
      tryAgain: 'Erneut versuchen',
      goHomepage: 'Zur Homepage',
    },
    welcome: {
      body: 'Wir freuen uns, dass Sie uns als Local-Admin für Ihre Institution beitreten können.\n\nKlicken Sie den unten stehenden Button, um Ihr erstes Passwort einzurichten. Danach werden Sie auf die Login Seite weitergeleitet, um den Login-Prozess zu vervollständigen.',
      button: 'Passwort einrichten',
      contact:
        'Falls Sie Unterstützung benötigen, kontaktieren Sie uns bitte über ',
      email: 'global.admin@docpad.com',
      greetings: 'Mit freundlichen Grüßen,\nTeam DocPad',
    },
    passwordSetup: {
      headline: 'Passwort setup',
      password: 'Passwort',
      passwordPlaceholder: 'Bitte geben Sie ein Passwort ein.',
      repeat: 'Passwort erneut eingeben',
      repeatPlaceholder: 'Bitte geben Sie das Passwort erneut ein.',
      button: 'Passwort einrichten',
    },
    start: {
      start: 'Start',
      headline: 'DocPad Local-Admin Plattform',
      body: 'Dies ist ein Service, der Ihnen dabei hilft, die Abteilungen und BenutzerInnen Ihrer Institution zu verwalten. Hier können Sie BenutzerInnen hinzufügen, ihnen Rollen und Abteilungen zuweisen, ihre Daten bearbeiten und sie deaktivieren. Darüber hinaus haben Sie die Möglichkeit, Abteilungen in Ihrer Institution hinzuzufügen, zu deaktivieren und zu bearbeiten sowie den Widerruf der Einwilligung zur Verarbeitung der Daten der PatientInnen anzustoßen.',
      button: 'Zum Login',
      disclaimer:
        'Das DocPad-Team hat sorgfältig über das Thema Gendering nachgedacht und entschieden, das Binnen-I als Gendersprache anzubieten. Als alternative Option kann eine generisch maskuline Sprache ausgewählt werden. Wir möchten betonen, dass unsere Entscheidung keinerlei Absicht hat, nicht-binäre Menschen zu diskriminieren. Wir schätzen und respektieren die Vielfalt unserer BenutzerInnen und sind bestrebt, inklusive und respektvolle Sprachoptionen bereitzustellen.',
    },
    login: {
      headline: 'Login',
      email: 'E-Mail-Adresse',
      emailPlaceholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
      password: 'Passwort',
      passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein.',
      forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
      button: 'Login',
    },
    resetPassword: {
      headline: 'Passwort zurücksetzen',
      body: 'Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich registriert haben. Wir werden Ihnen eine E-Mail mit einem Link zum Zurücksetzen des Passworts an diese E-Mail-Adresse zusenden.',
      email: 'E-Mail-Adresse',
      emailPlaceholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
      resetButton: 'Passwort zurücksetzen',
      loginButton: 'Login',
    },
    checkEmail: {
      headline: 'Überprüfen Sie Ihr E-Mail-Postfach',
      body: 'Wir haben Ihnen einen Link zum Zurücksetzen des Passworts an die E-Mail-Adresse --- zugesendet. Bitte sehen Sie in Ihrem Postfach nach und richten Sie sich ein neues Passwort ein.\n\nFalls Sie die E-Mail nicht vorfinden:',
      li1: 'sehen Sie in ihrem Spam-Ordner nach\n',
      li2: 'überprüfen Sie, ob die angegebene E-Mail-Adresse korrekt ist',
      button: 'Zurück zum Login',
    },
    newPasswordSetup: {
      headline: 'Neues Passwort einrichten',
      password: 'Neues Passwort',
      passwordPlaceholder: 'Bitte geben Sie ein neues Passwort ein.',
      repeat: 'Neues Passwort wiederholen',
      repeatPlaceholder: 'Bitte geben Sie Ihr neues Passwort erneut ein.',
      button: 'Passwort ändern',
    },
    updatePassword: {
      headline: 'Ihr Passwort wurde aktualisiert.',
      body: 'Gehen Sie zum Login und melden Sie sich erneut an.',
      button: 'Zum Login',
    },
    changePassword: {
      headline: 'Passwort ändern',
      oldPassword: 'Altes Passwort',
      oldPasswordPlaceholder: 'Bitte geben Sie Ihr altes Passwort ein.',
      newPassword: 'Neues Passwort',
      newPasswordPlaceholder: 'Bitte geben Sie ein neues Passwort ein.',
      repeatPassword: 'Neues Passwort wiederholen',
      repeatPasswordPlaceholder:
        'Bitte geben Sie Ihr neues Passwort erneut ein.',
      button: 'Passwort ändern',
    },
    dashboard: {
      header: {
        institution: 'Institution',
      },
      tabs: {
        profile: {
          headline: 'Profileinstellungen',
          profileInformation: 'Profileinstellungen',
          changePassword: 'Passwort ändern',
          passwordUpdated: 'Passwort wurde aktualisiert.',
          languageSetting: {
            english: '(EN) Englisch',
            german: '(DE) Deutsch',
            selectLanguageHeadline: 'Sprache auswählen',
            headline: 'Sprachauswahl und bevorzugte Gendersprache',
            selectedLanguage: 'Ausgewählte Sprache',
            languageGender: 'Bevorzugte Gendersprache',
            germanBinary: 'Binnen-I',
            germanMale: 'Generisch männlich',
            description:
              'Sie können die Sprache später in den Einstellungen ändern.',
          },
        },
        userManagement: {
          headline: 'Benutzerverwaltung',
          list: 'Liste der Benutzer',
          search: 'Suche',
          button: 'Benutzer hinzufügen',
          filterButtonShow: 'Deaktivierte Benutzer anzeigen',
          filterButtonHide: 'Deaktivierte Benutzer ausblenden',
          addUser: {
            headline: 'Benutzer hinzufügen',
            body: 'Füllen Sie unten stehende Felder aus, um einen Benutzer zu Ihrer Institution hinzufügen.',
            button: 'Benutzer hinzufügen',
            success: 'Benutzer erfolgreich hinzugefügt.',
            personalInformation: {
              headline: 'Persönliche Informationen',
              firstNamePlaceholder: 'Bitte geben Sie den Vornamen ein.',
              lastNamePlaceholder: 'Bitte geben Sie den Nachnamen ein.',
              titlePlaceholder: 'Bitte geben Sie den Titel ein.',
              genderPlaceholder: 'Bitte auswählen',
              birthdatePlaceholder: 'TT-MM-JJJJ',
              birthplacePlaceholder: 'Bitte geben Sie den Geburtsort ein.',
            },
            professionalInformation: {
              headline: 'Berufliche Informationen',
              email: 'E-Mail-Adresse',
              emailPlaceholder: 'Bitte geben Sie eine E-Mail-Adresse ein.',
              licenseNumber: 'Approbationsnummer',
              licenseNumberPlaceholder:
                'Bitte geben Sie die Approbationsnummer ein.',
              LANR: 'LANR',
              LANRPlaceholder: 'Bitte geben Sie die LANR ein.',
              speciality: 'Facharztstatus',
              specialityPlaceholder: 'Bitte auswählen',
              telephone: 'Telefonnummer',
              telephonePlaceholder: 'Bitte geben Sie eine Telefonnummer ein.',
            },
            details: {
              headline: 'Details',
              department: 'Abteilung',
              departmentPlaceholder: 'Bitte auswählen',
              userRole: 'Rolle',
              userRolePlaceholder: 'Bitte auswählen',
              info: 'In der Rolle des Supervisors ist der Benutzer verantwortlich für die Gegenzeichnung der Dokumente, die von der Assistenz erstellt wurden.',
            },
          },
          editUser: {
            headline: 'Benutzer bearbeiten',
            body: 'Um die Daten des Benutzers zu aktualisieren, nehmen Sie bitte die Änderungen hier vor und klicken Sie auf speichern.',
            success: 'Änderungen erfolgreich gespeichert.',
          },
          userDetails: {
            headline: 'Weitere Details',
            body: 'Hier sehen Sie weitere Information über den ausgewählten Benutzer.',
            deactivated: 'Dieser Nutzer ist deaktiviert.',
          },
          deactivation: {
            headline: 'Deaktivierung des Benutzerkontos',
            body: 'Sind Sie sich sicher, dass Sie unten genanntes Benutzerkonto in Ihrer Institution deaktivieren möchten?',
            disclaimer:
              '*Vergangene Interaktionen zwischen dem Benutzer und dem DocPad können weiterhin nachvollzogen werden.',
            success: 'Das Konto des Benutzers wurde erfolgreich deaktiviert',
          },
          listUpload: {
            headline: 'Upload',
            hint: 'Klicken oder Drag-and-Drop zum Upload einer CSV-Datei.',
            error:
              'Upload fehlgeschlagen. Bitte entfernen Sie die Datei und versuchen Sie es erneut.',
            button: 'Upload',
            notification: {
              error:
                'Importieren fehlgeschlagen. Das Format der CSV-Datei stimmt nicht mit den Vorraussetzungen überein. Bitte überprüfen Sie die Datei und versuchen Sie es erneut.',
              success: 'Datei erfolgreich importiert.',
            },
          },
        },
        departmentManagement: {
          headline: 'Verwaltung der Abteilungen',
          list: 'Abteilungsliste',
          search: 'Suche',
          button: 'Neue Abteilung hinzufügen',
          editDisclaimer: {
            headline: 'Diese Abteilung kann nicht bearbeitet werden',
            body: 'Eine Abteilung kann nicht mehr bearbeitet werden, falls ihr bereits ein Doktor oder eine Episode zugeordnet wurde.',
          },
          addDepartment: {
            headline: 'Abteilung hinzufügen',
            body: 'Füllen Sie unten stehende Felder aus, um eine Abteilung zu Ihrer Institution hinzuzufügen.',
            departmentNamePlaceholder:
              'Bitte geben Sie hier den Namen der Abteilung ein.',
            careSettingPlaceholder: 'Bitte auswählen',
            button: 'Abteilung hinzufügen',
            success: 'Abteilung erfolgreich hinzugefügt.',
          },
          editDepartment: {
            headline: 'Abteilung bearbeiten',
            body: 'Editieren Sie die unten stehenden Felder und speichern Sie die Änderungen, um die Daten der Abteilung zu aktualisieren.',
            success: 'Änderungen an der Abteilung erfolgreich gespeichert.',
          },
          deactivation: {
            headline: 'Abteilung deaktivieren',
            body: 'Sind Sie sich sicher, dass Sie die unten stehende Abteilung deaktivieren möchten?',
            disclaimer:
              '*Vergangene Interaktionen zwischen der Abteilung und dem DocPad können weiterhin nachvollzogen werden.',
            success: 'Abteilung erfolgreich deaktiviert.',
          },
        },
        patientRemoval: {
          headline: 'Widerruf der Einverständniserklärung',
          subline:
            'Informationen zum Widerruf des Einverständnisses zur koordinierten systemweiten Datenverarbeitung.',
          body: 'Zum Widerrufen des Einverständnisses eines Patienten zur koordinierten systemweiten Datenverarbeitung, muss der Datensicherheitsbeauftragte kontaktiert werden:',
          li1: 'E-Mail-Adresse:',
          li2: 'Telefon: +49 111111111',
          email: 'datensicherheistbeauftragter@docpad.com',
        },
      },
    },
    logout: {
      button: 'Logout',
      modalHeadline: 'Sind Sie sicher, dass Sie sich ausloggen möchten?',
      modalInfo:
        'Nach dem Logout müssen Sie sich erneut einloggen, um die Anwendung wie zuvor benutzen zu können.',
    },
    autoLogout: {
      title: 'Sie wurden automatisch ausgeloggt.',
      description: 'Sie wurden aus Sicherheitsgründen automatisch von der App ausgeloggt, da Sie längere Zeit keine Aktion durchgeführt haben.',
      loginButton: 'Erneuter Login'
    },
    errorPages: {
      maintenance: {
        header: 'Diese Seite befindet sich in Wartung',
        detail: '',
        info: '',
      },
      construction: {
        header: 'Diese Seite befindet sich im Aufbau',
        detail: 'Es gab Probleme beim Synchronisieren der Daten.',
        info: 'Versuchen Sie es erneut oder wenden Sie sich an den technischen Support/Local-Admin.',
      },
      noRights: {
        header: 'Sie besitzen nicht die Berechtigungen, diese Seite zu sehen',
        detail:
          'Sie besitzen nicht die Berechtigungen, Inhalte auf dieser Seite zu sehen.',
        info: 'Kontaktieren Sie Ihren Local-Admin.',
      },
      pageDoesntExist: {
        header: 'Diese Seite existiert nicht',
        detail:
          'Möglicherweise liegt es daran, dass diese Seite verschoben oder entfernt wurde, etwas nicht mit der Webadresse stimmt, ein technischer Fehler aufgetreten ist',
        info: 'Bitte versuchen Sie es später erneut. Sollte das Problem weiterhin auftreten, nehmen Sie bitte mit dem technischen Support Kontakt auf.',
      },
      cantBeReached: {
        header: 'Diese Seite kann nicht erreicht werden',
        detail: 'Es dauert zu lange die Seite zu laden.',
        info: 'Überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut. Falls weiterhin Probleme auftreten, kontaktieren Sie bitte den technischen Support.',
      },
      unableToShow: {
        header:
          'Entschuldigen Sie - diese Seite kann aktuell nicht angezeigt werden',
        detail:
          'Vermutlich besitzen Sie nicht die Berechtigungen, Inhalte auf dieser Seite zu sehen.',
        info: 'Bitte versuchen Sie es erneut oder kontaktieren Sie den technischen Support / Local-Admin.',
      },
    },
  },
};
