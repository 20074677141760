import { useEffect, useState } from 'react';
import ErrorDialog from '../components/errorDialog/errorDialog';
import constate from 'constate';
import { eventSubscriber } from '../helpers/customEvents';

const useError = () => {
  const [error, setError] = useState('');
  return { error, setError };
};

export const [GeneralErrorProvider, useGeneralErrorContext] =
  constate(useError);

const ErrorComponent = () => {
  const { error, setError } = useGeneralErrorContext();

  useEffect(() => {
    eventSubscriber('badResponse', (data: string) => setError(data));
  }, []);

  return (
    <ErrorDialog
      isOpen={error !== ''}
      onClose={() => setError('')}
      errorTitle={'Error'}
      description={error}
      primaryButtonLabel={'OK'}
    />
  );
};

export const ErrorProvider = ({ children }: { children: any }) => {
  return (
    <GeneralErrorProvider>
      {children}
      <ErrorComponent />
    </GeneralErrorProvider>
  );
};
