import { Outlet, createBrowserRouter } from 'react-router-dom';
import Keycloak from 'keycloak-js';

import { PrivateGuard, PublicGuard } from './components/guards';
import SelectLanguage from './screens/auth/language';
import Login from './screens/auth/login';
import Dashboard from './screens/dashboard';
import SetPassword from './screens/auth/set-password';
import Welcome from './screens/welcome';
import UserManagement from './screens/dashboard/userManagement';
import Settings from './screens/dashboard/profile';
import AddManagementEdit from './screens/dashboard/userManagement/create-form';
import AddDepartmentEdit from './screens/dashboard/departmentManagement/create-form';
import DepartmentManagement from './screens/dashboard/departmentManagement';
import PatientRemoval from './screens/dashboard/patient-removal';
import AutoLogout from './screens/logout';

export const router = (keycloak: Keycloak) =>
  createBrowserRouter([
    {
      path: '/',
      element: <PublicGuard isAuthenticated={keycloak.authenticated} />,
      children: [
        {
          index: true,
          element: <SelectLanguage />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'welcome',
          element: <Welcome />,
        },
        {
          path: 'set-password',
          element: <SetPassword isReset={false} />,
        },
        {
          path: 'reset-password',
          element: <SetPassword isReset />,
        },
      ],
    },
    {
      path: '/auto-logout',
      element: <AutoLogout />,
    },
    {
      path: '/change-password',
      element: (
        <PrivateGuard isAuthenticated={keycloak.authenticated}>
          <SetPassword isChange />
        </PrivateGuard>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <PrivateGuard isAuthenticated={keycloak.authenticated}>
          <Dashboard />
        </PrivateGuard>
      ),
      children: [
        {
          path: 'profile',
          element: <Settings />,
        },
        {
          path: 'user-management',
          element: <UserManagement />,
          children: [
            {
              index: true,
            },
            {
              path: 'create',
              element: <AddManagementEdit />,
            },
            {
              path: 'edit/:id',
              element: <AddManagementEdit />,
            },
            {
              path: 'details/:id',
            },
          ],
        },
        {
          path: 'department-management',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <DepartmentManagement />,
            },
            {
              path: 'create',
              element: (
                <AddDepartmentEdit
                  mode="add"
                  open={true}
                  onClose={() => {}}
                  groupId=""
                />
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <AddDepartmentEdit mode="edit" open={true} onClose={() => {}} />
              ),
            },
          ],
        },
        {
          path: 'patient-removal',
          element: <PatientRemoval />,
        },
      ],
    },
  ]);
