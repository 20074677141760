import { useQuery } from '@tanstack/react-query';
import apiClient from '../apiClient';
import { AxiosResponse } from 'axios';
import { SpecialityItem } from '../open-api';

export const useMedicalSpecialistList = () => {
  return useQuery({
    queryKey: ['medicalSpecialist'],
    queryFn: async (): Promise<SpecialityItem[]> => {
      return apiClient
        .getLocalAdminAPI()
        .getMedicalSpecialitiesLocalAdminSpecialistStatusGet()
        .then((response: AxiosResponse) => response.data);
    },
  });
};
