import { TinyEmitter } from 'tiny-emitter';

const emitter = new TinyEmitter();

export function eventSubscriber(eventName: string, listener: Function) {
  emitter.on(eventName, listener);
}

export function eventDispatcher(eventName: string, data?: any) {
  emitter.emit(eventName, { data });
}

export function eventUnsubscriber(eventName: string, listener?: Function) {
  emitter.off(eventName, listener);
}
