import { useTranslation } from 'react-i18next';
import {
  displayDepartments,
  displayString,
  groupsAsString,
} from '../../../helpers/users';
import { useUserById } from '../../../localAdmin-api/queries/user';

interface IDetails {
  userId: string;
}

const Details = ({ userId }: IDetails) => {
  const { t } = useTranslation();
  const { data: user } = useUserById(userId);
  const groupsString = groupsAsString(user?.groups);

  return (
    <>
      {!user?.enabled && (
        <p className="titleS text-semibold mb-spacing4">
          {t('dashboard.tabs.userManagement.userDetails.deactivated')}
        </p>
      )}
      <div className="flex gap-spacing9 mb-spacing8">
        <div className="flex-1 greyBox">
          <p className="font-semibold titleM mb-spacing8">
            {t(
              'dashboard.tabs.userManagement.addUser.personalInformation.headline',
            )}
          </p>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.firstName')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.firstName)}
            </p>
          </div>

          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.lastName')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.lastName)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.title')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.title)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.gender')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.gender)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.birthdate')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.birthdate)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.birthplace')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.birthplace)}
            </p>
          </div>
        </div>
        <div className="flex-1 h-full greyBox">
          <p className="font-semibold titleM mb-spacing8">
            {t(
              'dashboard.tabs.userManagement.addUser.professionalInformation.headline',
            )}
          </p>

          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.email')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.email)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t(
                'dashboard.tabs.userManagement.addUser.professionalInformation.licenseNumber',
              )}
              :
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.approbationNumber)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('general.LANR')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.lanr)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t(
                'dashboard.tabs.userManagement.addUser.professionalInformation.speciality',
              )}
              :
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.facharztStatus)}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t(
                'dashboard.tabs.userManagement.addUser.professionalInformation.telephone',
              )}
              :
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(user?.attributes?.phone)}
            </p>
            <p className="font-semibold bodyL mb-spacing6"></p>
          </div>
        </div>
        <div className="flex-1 h-full greyBox">
          <p className="font-semibold titleM mb-spacing8">
            {t('dashboard.tabs.userManagement.addUser.details.headline')}
          </p>

          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('dashboard.tabs.userManagement.addUser.details.department')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(
                displayDepartments(groupsString.departments!)?.join(', '),
              )}
            </p>
          </div>
          <div className="flex">
            <p className="bodyL mr-spacing4 mb-spacing6">
              {t('dashboard.tabs.userManagement.addUser.details.userRole')}:
            </p>
            <p className="font-semibold bodyL mb-spacing6">
              {displayString(t(`general.${groupsString.role}`))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
