import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { SearchInput } from '../../../components/input';
import Button from '../../../components/button/button';
import Table from '../../../components/table/table';
import AddEdit from './create-form';
import Deactivation from '../deactivate-modal';
import { compact, flatMapDeep } from 'lodash';
import { matchSorter } from 'match-sorter';
import {
  getCareSettingFromPath,
  useFilteredGroups,
} from '../../../helpers/hooks/groups';
import { useNavigate } from 'react-router-dom';

type DepartmentType = 'add' | 'edit';

const DepartmentManagement = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState<DepartmentType>('add');
  const [deactivationVisible, setDeactivationVisible] = useState(false);
  const [groupId, setGroupId] = useState('');

  const [searchQuery, setSearchQuery] = useState('');
  const filteredGroups = useFilteredGroups();
  const navigate = useNavigate();

  const departments = useMemo(() => {
    return compact(
      flatMapDeep(filteredGroups, (aGroup) => {
        return aGroup.subGroups;
      }).map((aSub: any) => {
        if (aSub.attributes.enabled?.[0] !== 'false')
          return {
            id: aSub?.id,
            name: aSub?.name,
            careSetting: t(
              `careSettings.${getCareSettingFromPath(aSub?.path)}`,
            ),
            editable: !aSub?.episodeCheck,
          };
      }),
    );
  }, [filteredGroups]);

  const filteredDepartments = useMemo(() => {
    return matchSorter(departments, searchQuery, {
      keys: ['name', 'careSetting'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    });
  }, [departments, searchQuery]);

  const tableHead = [
    'id',
    t('general.departmentName'),
    t('general.careSetting'),
  ];

  const openModal = () => {
    setModalVisible(true);
  };

  const onAdd = () => {
    openModal();
    setModalType('add');
    navigate('/dashboard/department-management/create');
  };

  const onEdit = (id: string) => {
    setGroupId(id);
    openModal();
    setModalType('edit');
    navigate(`/dashboard/department-management/edit/${id}`);
  };

  const onRemove = (id: string) => {
    setGroupId(id);
    setDeactivationVisible(true);
  };

  const onCloseModal = () => {
    setModalVisible(false);
    setGroupId('');
  };

  const onCloseDeactivation = () => {
    setDeactivationVisible(false);
    setGroupId('');
  };

  return (
    <>
      <div className="flex items-center justify-between mb-spacing6">
        <div>
          <p className="titleM w-[180px]">
            {t('dashboard.tabs.departmentManagement.list')}
          </p>
        </div>
        <div className="flex justify-between gap-spacing4">
          <SearchInput
            placeholder={t('dashboard.tabs.departmentManagement.search')}
            onChange={(event) => setSearchQuery(event.target.value)}
            value={searchQuery}
          />
          <Button
            onPress={onAdd}
            icon={<Plus />}
            data-cy="button-add-department"
          >
            {t('dashboard.tabs.departmentManagement.button')}
          </Button>
        </div>
      </div>
      {departments && departments.length > 0 && (
        <Table
          tableHead={tableHead}
          tableData={filteredDepartments}
          onEdit={(id) => onEdit(id)}
          onRemove={(id) => onRemove(id)}
        />
      )}
      <AddEdit
        mode={modalType}
        open={modalVisible}
        onClose={onCloseModal}
        groupId={groupId}
      />
      <Deactivation
        mode={'department'}
        open={deactivationVisible}
        onClose={onCloseDeactivation}
        groupId={groupId}
      />
    </>
  );
};

export default DepartmentManagement;
