import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  DE_X,
  SELECTED_LANGAUGE_STORAGE_KEY,
} from '../../constants/languageOptions';
import { UserRepresentation } from '../../localAdmin-api/open-api';
import { useUpdateUser, useUserById } from '../../localAdmin-api/queries/user';
import useLocalStorage from './useLocalStorage';

export const useGetUserProfile = () => {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { keycloak, initialized } = useKeycloak();
  const [userId, setUserId] = useState<string | undefined>();
  const { data: profile } = useUserById(userId);
  const updateUser = useUpdateUser(userId || '');
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage<string>(
    SELECTED_LANGAUGE_STORAGE_KEY,
    DE_X,
  );

  const handleUpdateUser = (payload: UserRepresentation) => {
    updateUser.mutate(payload, {
      onSuccess: () => {
        if (payload?.attributes?.languageSelection?.[0]) {
          setSelectedLanguage(payload?.attributes?.languageSelection?.[0]);
        }
        queryClient.invalidateQueries({ queryKey: ['user', userId] });
      },
    });
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (
      profile &&
      profile?.attributes?.languageSelection?.[0] !== selectedLanguage
    ) {
      handleUpdateUser({
        ...profile,
        attributes: {
          ...profile.attributes,
          languageSelection: [selectedLanguage],
        },
      });
    }
  }, [profile]);

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      keycloak.loadUserProfile().then((record) => {
        setUserId(record.id);
      });
    }
  }, [keycloak, initialized]);

  return {
    userId,
    profile,
    selectedLanguage: profile?.attributes?.languageSelection?.[0] ?? DE_X,
    onUpdateUser: handleUpdateUser,
  };
};
