import { FC, useState } from 'react';
import CustomModal from '../../../components/modal/modal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadFile } from '../../../assets/icons/uploadFile.svg';
import { ReactComponent as UploadedFile } from '../../../assets/icons/uploadedFile.svg';
import { ReactComponent as UploadFileLarge } from '../../../assets/icons/uploadFileLarge.svg';
import { ReactComponent as Remove } from '../../../assets/icons/remove.svg';
import Button from '../../../components/button/button';
import { FileUploader } from 'react-drag-drop-files';
import { clsx } from 'clsx';

interface IListUpload {
  open: boolean;
  onClose: () => void;
}

const ListUpload: FC<IListUpload> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | null>(null);

  const onUpload = (newFile: any) => {
    setFiles(newFile);
  };

  const onModalClose = () => {
    onRemoveFiles();
    onClose();
  };

  const onRemoveFiles = () => {
    setFiles(null);
  };

  return (
    <CustomModal isOpen={open} onClose={onModalClose}>
      <p className="font-semibold titleL mb-spacing8">
        {t('dashboard.tabs.userManagement.listUpload.headline')}
      </p>
      <FileUploader
        label={t('dashboard.tabs.userManagement.listUpload.hint')}
        multiple={true}
        handleChange={onUpload}
        onDrop={onUpload}
        name="file"
        disabled={!!files}
        types={['CSV']}
        hoverTitle={' '} // hide default text
      >
        <div
          className={`${clsx('p-spacing10 rounded-spacing4 border-dashed border-2 border-border-grayInputDefault', { 'bg-surface-disabled': files })}`}
        >
          <div
            className={`${clsx('text-text-brandPrimary w-[80px] h-[80px] mb-spacing10 transform scale-2 m-auto', { 'text-text-disabled': files })}`}
          >
            <UploadFileLarge />
          </div>
          <p
            className={`${clsx('titleM font-semibold text-center', { 'text-text-disabled': files })}`}
          >
            {t('dashboard.tabs.userManagement.listUpload.hint')}
          </p>
        </div>
      </FileUploader>
      {!!files && (
        <div className="mt-spacing8">
          <div className="flex justify-between">
            <div className="flex">
              <div className="mr-spacing4">
                <UploadedFile />
              </div>
              <p>
                {Object.values(files)
                  .map((file) => {
                    return file.name;
                  })
                  .join(' ')}
              </p>
            </div>
            <button onClick={onRemoveFiles}>
              <Remove />
            </button>
          </div>
          <div className="mt-spacing8">
            <Button onPress={() => {}} icon={<UploadFile />}>
              {t('dashboard.tabs.userManagement.listUpload.button')}
            </Button>
          </div>
        </div>
      )}
    </CustomModal>
  );
};

export default ListUpload;
