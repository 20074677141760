// styled components for multi dropdown
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

export const Menu = (props: any) => {
  return (
    <div
      className="w-full bg-surface-primary absolute border border-border-grayInputDefault rounded-spacing2 z-20 mt-spacing1"
      {...props}
    />
  );
};

export const Blanket = (props: any) => (
  <div className="fixed inset-0 z-10" {...props} />
);

export const Dropdown = ({ children, isOpen, target, onClose }: any) => (
  <div style={{ position: 'relative' }} className={``}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export const DropdownIndicator = () => (
  <div className={`p-spacing1 mr-spacing2`}>
    <SearchIcon />
  </div>
);
