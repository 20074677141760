import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import apiClient from '../../localAdmin-api/apiClient';
import FilterButton from '../../components/button/filterButton';
import Sidebar from '../../components/sidebar';
import { useSidebarLinks } from '../../components/sidebar/constants';

const Dashboard = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const sidebarLinks = useSidebarLinks();
  const selectedSidebarLink = sidebarLinks.find(
    (link) => link.pathname === location.pathname,
  );
  const headline = selectedSidebarLink?.label;
  const [disabledUsersVisible, setDisabledUsersVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (keycloak?.authenticated && keycloak.token) {
      apiClient.setToken(keycloak?.token);
    }
  }, [keycloak?.authenticated, keycloak?.token]);

  useEffect(() => {
    if (location.pathname == '/dashboard/user-management') {
      disabledUsersVisible
        ? navigate('/dashboard/user-management', {
            state: { disabledVisible: true },
          })
        : navigate('/dashboard/user-management', {
            state: { disabledVisible: false },
          });
    }
  }, [disabledUsersVisible]);

  return (
    <div className="flex gap-spacing9">
      <Sidebar />
      <div className="w-full">
        <div className="flex">
          <span className="titleL mb-spacing8">{headline}</span>
          {location.pathname === '/dashboard/user-management' && (
            <FilterButton
              onPress={() => setDisabledUsersVisible(!disabledUsersVisible)}
              active={disabledUsersVisible}
            />
          )}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
