import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiClient from '../apiClient';
import { KeycloakProfile } from 'keycloak-js';

export const useUserGroups = (userId: string) => {
    return useQuery({
        enabled: !!userId && userId !== '',
        queryKey: ['userDepartments', userId],
        queryFn: async (): Promise<Group[]> => {
            return apiClient
                .getAxiosInstance()
                .get(
                    apiClient.getKeycloakUrl(`users/${userId}/groups`),
                    apiClient.getAxiosConfig(),
                )
                .then((response: AxiosResponse) => response.data);
        },
    });
};

export const useAddUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: KeycloakProfile) => {
      // TODO: Change this to use internal Docpad API instead of Keycloak API
      const { data } = await apiClient
        .getAxiosInstance()
        .post(
          apiClient.getKeycloakUrl(`users`),
          payload,
          apiClient.getAxiosConfig(),
        );
      return data;
    },
    onError: (err) => console.log(err),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};
