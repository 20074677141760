import { Navigate, Outlet } from 'react-router-dom';

const PublicGuard = ({ isAuthenticated }: { isAuthenticated?: boolean }) => {
  if (isAuthenticated) {
    return <Navigate to="/dashboard/profile" />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default PublicGuard;
