import React, { ReactElement, ReactNode } from 'react';
import { clsx } from 'clsx';

interface IButton {
  backgroundStyle?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  children?: ReactNode;
  onPress?: () => void;
  disabled?: boolean;
  icon?: ReactElement;
  centered?: boolean;
  fullWidth?: boolean;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<IButton> = ({
  children,
  onPress,
  backgroundStyle = 'primary',
  disabled,
  icon,
  centered,
  fullWidth,
  id,
  type = 'button',
  ...rest
}) => {
  const buttonStyles = clsx(
    'flex flex-row max-h-spacing11 titleXS px-spacing6 py-spacing5 rounded-spacing11 focus:border-2 focus:border-border-dark items-center',
    {
      'w-full': fullWidth,
      'p-spacing5 w-spacing11 h-spacing11': !children,
      'justify-center':
        backgroundStyle === 'primary' ||
        backgroundStyle === 'secondary' ||
        centered,
      'text-text-disabled': disabled,
      'bg-button-primarySecondaryDisabled':
        disabled &&
        (backgroundStyle === 'primary' || backgroundStyle === 'secondary'),
      'bg-button-primaryDefault hover:bg-button-secondaryDefault focus:bg-button-secondaryDefault active:bg-button-primaryPressed text-text-invert hover:text-text-brandPrimary focus:text-text-brandPrimary active:text-text-invert':
        backgroundStyle === 'primary' && !disabled,
      'bg-button-secondaryDefault hover:bg-button-primaryDefault focus:bg-button-primaryDefault active:bg-button-secondaryPressed text-text-brandPrimary hover:text-text-invert focus:text-text-invert active:text-text-brandPrimary':
        backgroundStyle === 'secondary' && !disabled,
      'justify-start bg-transparent hover:focus:bg-transparent text-text-brandPrimary hover:text-text-primary focus:text-text-primary active:text-text-brandPrimary bg-transparent hover:focus:bg-transparent hover:text-text-brandPrimary focus:text-text-brandPrimary active:text-text-primary':
        backgroundStyle === 'tertiary' && !disabled,
    },
  );

  return (
    <button
      {...rest}
      disabled={disabled}
      className={buttonStyles}
      onClick={onPress}
      id={id}
      type={type}
    >
      {!!icon && (
        <div className={clsx({ 'mr-spacing4': !!children })}>{icon}</div>
      )}
      {children}
    </button>
  );
};

export default Button;
