import apiClient from '../../localAdmin-api/apiClient';
import { useQuery } from '@tanstack/react-query';
import { DepartmentRepresentation } from '../open-api';

export const useGetDepartments = () => {
  return useQuery({
    queryKey: ['departments'],
    queryFn: async (): Promise<DepartmentRepresentation[]> => {
      const { data } = await apiClient
        .getKeycloakExtensionsAPI()
        .getDepartmentHierarchyKeycloakExtensionsDepartmentsGet();
      return data;
    },
  });
};
