import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RightContainer from '../../components/login/rightContainer';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/button';
import { InputText } from '../../components/input';

interface ISetPassword {
  isReset?: boolean;
  isChange?: boolean;
}

const SetPassword = ({ isReset, isChange }: ISetPassword) => {
  const [successScreen, setSuccessScreen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onResetPassword = () => {
    setSuccessScreen(true);
  };

  const onChangePassword = () => {
    navigate('/dashboard', { state: { updatedPassword: true } });
  };

  const onNavigateToLogin = () => {
    navigate('/');
  };

  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-0 min-w-1280 p-0-16 m-0-auto">
      <div className="flex justify-center">
        <div className="self-center flex flex-col w-[392px]">
          {!successScreen ? (
            <>
              <p className="titleXXL mb-spacing10">
                {isReset
                  ? t('newPasswordSetup.headline')
                  : isChange
                    ? t('changePassword.headline')
                    : t('passwordSetup.headline')}
              </p>
              <form>
                {isChange && (
                  <div className="mb-spacing10">
                    <InputText
                      label={t('changePassword.oldPassword')}
                      placeholder={t('changePassword.oldPasswordPlaceholder')}
                      disabled={false}
                      onChange={() => {}}
                      value={''}
                    />
                  </div>
                )}
                <div className="mb-spacing10">
                  <InputText
                    label={
                      isReset
                        ? t('newPasswordSetup.password')
                        : isChange
                          ? t('changePassword.newPassword')
                          : t('passwordSetup.password')
                    }
                    placeholder={
                      isReset
                        ? t('newPasswordSetup.passwordPlaceholder')
                        : isChange
                          ? t('changePassword.newPasswordPlaceholder')
                          : t('passwordSetup.passwordPlaceholder')
                    }
                    disabled={false}
                    onChange={() => {}}
                    value={''}
                  />
                </div>
                <div className="mb-spacing10">
                  <InputText
                    label={
                      isReset
                        ? t('newPasswordSetup.repeat')
                        : isChange
                          ? t('changePassword.repeatPassword')
                          : t('passwordSetup.repeat')
                    }
                    placeholder={
                      isReset
                        ? t('newPasswordSetup.repeatPlaceholder')
                        : isChange
                          ? t('changePassword.repeatPasswordPlaceholder')
                          : t('passwordSetup.repeatPlaceholder')
                    }
                    disabled={false}
                    onChange={() => {}}
                    value={''}
                  />
                </div>
                <Button
                  onPress={
                    isReset
                      ? onResetPassword
                      : isChange
                        ? onChangePassword
                        : onNavigateToLogin
                  }
                  fullWidth
                >
                  {isReset
                    ? t('newPasswordSetup.button')
                    : isChange
                      ? t('changePassword.button')
                      : t('passwordSetup.button')}
                </Button>
              </form>
            </>
          ) : (
            <>
              <p className="titleXXL mb-spacing10">
                {t('updatePassword.headline')}
              </p>
              <p className="bodyM mb-spacing10 whitespace-pre-wrap h-[142px]">
                {t('updatePassword.body')}
              </p>
              <Button onPress={onNavigateToLogin}>
                {t('updatePassword.button')}
              </Button>
            </>
          )}
        </div>
      </div>
      <RightContainer />
    </div>
  );
};

export default SetPassword;
