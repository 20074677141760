import Select, {
  DropdownIndicatorProps,
  components,
  OptionProps,
} from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevronUp.svg';

type OptionType = {
  label: string;
  value: string;
};

interface IDropdown {
  id?: string;
  label: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  onChange: (e: any) => void;
}

const Dropdown = ({
  id,
  label,
  options,
  placeholder,
  disabled,
  value,
  onChange,
}: IDropdown) => {
  const { t } = useTranslation();

  const onSelect = (e: any) => {
    onChange(e);
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<OptionType>) => {
    return (
      <components.DropdownIndicator {...props}>
        <div
          className={`text-icon-primary`}
          id={'react-select-dropdown-indicator-' + label}
        >
          {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
      </components.DropdownIndicator>
    );
  };

  const Option = (props: OptionProps<OptionType>) => (
    <components.Option {...props}>
      <div
        id="react-select-option"
        className={`bodyM p-spacing5 flex flex-row justify-between ${props.isSelected && 'bg-surface-visualSeparator'} dropdown-option`}
      >
        <div>
          <p>{props.data.label}</p>
        </div>
        {props.isSelected && (
          <div className={`text-icon-positive`}>
            <CheckIcon />
          </div>
        )}
      </div>
    </components.Option>
  );

  return (
    <div className={`flex flex-col`}>
      <div className={`bodyS px-spacing5 py-spacing4 flex justify-self-start`}>
        {label}
      </div>
      <Select
        id={id}
        form={'none'}
        unstyled={true}
        styles={{
          input: (baseStyles) => ({
            ...baseStyles,
            color: 'transparent',
          }),
        }}
        isSearchable={false}
        classNames={{
          control: (state) =>
            `border rounded-spacing2 border-border-grayInputDefault bodyM pl-spacing5
            ${
              state.isDisabled
                ? 'border-border-grayControlsDefaultDisabled bg-surface-disabled'
                : state.isFocused
                  ? 'bg-surface-primary active:border-border-brandPrimary'
                  : 'bg-surface-primary hover:border-border-brandSecondary'
            }`,
          menu: () =>
            `bg-surface-primary border rounded-spacing2 border-border-grayInputDefault bodyM`,
          singleValue: () => `bodyM flex justify-self-start`,
          indicatorsContainer: () => `p-spacing5`,
        }}
        components={{
          Option: Option,
          DropdownIndicator: DropdownIndicator,
        }}
        value={options.filter((option) => {
          return option.value === value;
        })}
        onChange={onSelect}
        options={options}
        placeholder={placeholder || t('general.selectPlaceholder')}
        isDisabled={disabled}
        openMenuOnClick={true}
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default Dropdown;
