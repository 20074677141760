import { clsx } from 'clsx';
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import 'react-circular-progressbar/dist/styles.css';

import { ReactComponent as Close } from '../../assets/icons/close.svg';
import ProgressBar from './progressBar';

const TOAST_DURATION = 5000;

const NotificationToast = () => {
  return (
    <Toaster toastOptions={{ duration: TOAST_DURATION }} position="top-right">
      {(t) => {
        return (
          <div
            className={clsx(
              'flex mt-spacing15 px-spacing6 py-spacing5 bannerBorderRadius text-text-invert shadow-none',
              {
                'bg-surface-negative': t.type === 'error',
                'bg-surface-positive': t.type !== 'error',
                'opacity-0	': !t.visible,
                'opacity-100': t.visible,
              },
            )}
            style={{ width: '518px' }}
          >
            <button
              onClick={() => toast.dismiss(t.id)}
              className="relative flex items-center mr-spacing5 text-icon-invert"
            >
              <ProgressBar duration={TOAST_DURATION} />
              <div className="absolute left-spacing3">
                <Close className="fill-icon-invert" />
              </div>
            </button>
            <p className="font-semibold titleM text-text-invert">
              {resolveValue(t.message, t)}
            </p>
          </div>
        );
      }}
    </Toaster>
  );
};

export default NotificationToast;
